import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import { Empty } from '..';
import ChartSkeleton from '../Skeletons/XYZChart';

type Props = {
  data?: Record<string, any>[],
  lineKey?: string,
  xAxisKey?: string,
  stroke?: string,
  loading?: boolean
}

const dataa = [
  {
    name: 'Jan 21',
    transactions: 4000,
  },
  {
    name: 'Feb 21',
    transactions: 3000,
  },
  {
    name: 'Mar 21',
    transactions: 2000,
  },
  {
    name: 'May 21',
    transactions: 2780,
  },
  {
    name: 'Jun 21',
    transactions: 1890,
  },
  {
    name: 'Jul 21',
    transactions: 2390,
  },
  {
    name: 'Aug 21',
    transactions: 3490,
  },
];

const Linechart: React.FC<Props> = ({
  data = dataa, lineKey = 'transactions', xAxisKey = 'name', stroke = '#4186e0', loading,
}) => {
  if (loading) return <ChartSkeleton />;
  if (data.length === 0) return <Empty />;
  return (
    <LineChart
      width={1200}
      height={250}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="2 2" />
      <XAxis dataKey={xAxisKey} allowDataOverflow />
      <YAxis domain={[0, 500000]} />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey={lineKey} stroke={stroke} />
    </LineChart>
  );
};

export default Linechart;
