import React from 'react';

type Props = {
    children: React.ReactNode,
    title: string
}

const Content: React.FC<Props> = ({ title, children }: Props) => (
  <main className="relative z-10 h-full pb-16 overflow-y-auto">
    <div className="container px-6 mx-auto grid">
      <h2
        className="my-6 text-2xl font-semibold
        text-gray capitalize"
      >
        {title}
      </h2>
      {children}
    </div>
  </main>
);

export default Content;
