import React from 'react';
import { toast } from 'react-toastify';
import {
  Layout,
  Content,
  Tabs,
} from '../../../components';
import { useSchoolAdminMenu } from '../local-helpers/MenuItems';
import PaymentAccount from './PaymentAccount';
import CreateService from './CreateService';
import Fines from './Fines';
import { useCurrentUser } from '../../../contexts/UserContext';

const Settings: React.FC = () => {
  const menu = useSchoolAdminMenu();
  React.useEffect(() => () => toast.dismiss(), []);
  const { user } = useCurrentUser();
  const appliesFines = user.schools[0].applies_fine || false;
  return (
    <Layout menuItems={menu}>
      <Content title="Settings">
        {appliesFines ? (
          <Tabs>
            Services
            <CreateService />
            Payment
            <PaymentAccount />
            Fines
            <Fines />
          </Tabs>
        ) : (
          <Tabs>
            Services
            <CreateService />
            Payment
            <PaymentAccount />
          </Tabs>
        )}
      </Content>
    </Layout>
  );
};

export default Settings;
