import React from 'react';
import ContentLoader from 'react-content-loader';

interface Props {
    [x: string]: any
}

/**
 *
 * @param props
 * @copyright - name: 'XYChart',
    github: 'ankursehdev',
    description: 'XY Chart Loading',
    filename: 'XYChart',
 */

const XYChart: React.FC<Props> = ({ ...props }) => (
  <ContentLoader viewBox="0 0 400 680" height={400} width={680} {...props}>
    <rect x="20" y="5" rx="0" ry="0" width="2" height="180" />
    <rect x="20" y="175" rx="0" ry="0" width="360" height="2" />

    <rect x="40" y="75" rx="0" ry="0" width="35" height="110" />
    <rect x="80" y="125" rx="0" ry="0" width="35" height="60" />
    <rect x="120" y="105" rx="0" ry="0" width="35" height="80" />
    <rect x="160" y="35" rx="0" ry="0" width="35" height="150" />
    <rect x="200" y="55" rx="0" ry="0" width="35" height="130" />
    <rect x="240" y="15" rx="0" ry="0" width="35" height="170" />
    <rect x="280" y="135" rx="0" ry="0" width="35" height="50" />
    <rect x="320" y="85" rx="0" ry="0" width="35" height="100" />
  </ContentLoader>
);

export default XYChart;
