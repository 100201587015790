import React from 'react';
import Moment from 'moment';
import { Modal } from '../../components';

type Props = {
  isViewMore: boolean,
  onClose: () => void,
  details: Record<string, any>,
  isGasabo: boolean
}

const TransactionDetails: React.FC<Props> = ({
  isViewMore, onClose, details, isGasabo,
}) => {
  const [transaction, setTransaction] = React.useState<Record<string, any>>({});
  React.useEffect(() => {
    setTransaction(details);
  }, [details]);

  return (
    <Modal title="Transaction details" isOpen={isViewMore} onClose={onClose}>
      <div className="flex flex-wrap text-base">
        <div className="w-1/2 flex flex-col justify-between">
          {!isGasabo && <span className="font-bold text-black mb-1">Academic year: </span>}
          <span className="font-bold text-black mb-1">Amount: </span>
          <span className="font-bold text-black mb-1">Bank account: </span>
          <span className="font-bold text-black mb-1">Channel: </span>
          <span className="font-bold text-black mb-1">Currency: </span>
          <span className="font-bold text-black mb-1">
            {isGasabo ? 'Zone:' : 'Department:'}
          </span>
          <span className="font-bold text-black mb-1">Description: </span>
          <span className="font-bold text-black mb-1">External transaction ID: </span>
          <span className="font-bold text-black mb-1">Payed on: </span>
          <span className="font-bold text-black mb-1">Payer ID: </span>
          <span className="font-bold text-black mb-1">Payer name: </span>
          <span className="font-bold text-black mb-1">Payer phone number: </span>
          <span className="font-bold text-black mb-1">
            {isGasabo ? 'Organization:' : 'School:'}
          </span>
          <span className="font-bold text-black mb-1">Service: </span>
          <span className="font-bold text-black mb-1">Service description: </span>
          <span className="font-bold text-black mb-1">Slip number: </span>
          <span className="font-bold text-black mb-1">Status: </span>
          <span className="font-bold text-black mb-1">{isGasabo ? 'Month:' : 'Term:'}</span>
          <span className="font-bold text-black mb-1">Transaction created on: </span>
        </div>
        <div className="w-1/2 flex flex-col">
          {!isGasabo && (
          <span className="font-light mb-1">
            {transaction?.academic_year || '--'}
          </span>
          )}
          <span className="font-light mb-1">{transaction?.amount || '--'}</span>
          <span className="font-light mb-1">
            {transaction?.bank_account?.bank_account_number || '--'}
          </span>
          <span className="font-light mb-1">{transaction?.payment_channel_name || '--'}</span>
          <span className="font-light mb-1">{transaction?.currency || '--'}</span>
          <span className="font-light mb-1">{transaction?.department?.name || '--'}</span>
          <span className="font-light mb-1">{transaction?.description || '--'}</span>
          <span className="font-light mb-1">{transaction?.external_transaction_id || '--'}</span>
          <span className="font-light mb-1">
            {transaction?.payment_date_time ? Moment(transaction?.payment_date_time)
              .format('DD/MM/YYYY, h:mm:ss a') : '--'}
          </span>
          <span className="font-light mb-1">{transaction?.user?.id || '--'}</span>
          <span className="font-light mb-1">
            {transaction?.user?.first_name || ''}
            {transaction?.user?.last_name || '--'}
          </span>
          <span className="font-light mb-1">
            +
            {transaction?.user?.phone_number || '--'}
          </span>
          <span className="font-light mb-1">{transaction?.school?.name || '--'}</span>
          <span className="font-light mb-1">{transaction?.service?.name || '--'}</span>
          <span className="font-light mb-1">{transaction?.service?.description || '--'}</span>
          <span className="font-light mb-1">{transaction?.slip_number || '--'}</span>
          <span className="font-light mb-1">{transaction?.status || '--'}</span>
          <span className="font-light mb-1">{transaction?.term || '--'}</span>
          <span className="font-light mb-1">
            {transaction?.created_at ? Moment(transaction?.created_at)
              .format('DD/MM/YYYY, h:mm:ss a') : '--'}
          </span>
        </div>
      </div>
    </Modal>

  );
};

export default TransactionDetails;
