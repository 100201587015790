import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  Button, InputText, Select, FormLayout,
} from '../../../components';
import School, { IPaymentAccount } from '../local-helpers/requests';
// import { AccFormat, bkAcc } from '../../../helpers/regex';
import { ISelectedValue } from '../../../components/Select';
import { useCurrentUser } from '../../../contexts/UserContext';

const PaymentAccount: React.FC = () => {
  const { user } = useCurrentUser();
  const options = [
    { value: 'BK', label: 'Bank of Kigali (BK)' },
    { value: 'SACCO', label: 'Umwalimu SACCO' },
  ];
  const API = new School();
  React.useEffect(() => () => toast.dismiss(), []);

  const {
    mutate, isLoading,
  } = useMutation((payload: IPaymentAccount) => API.createPaymentAccount(payload,
    user.schools[0].id));

  const formik = useFormik({
    initialValues: {
      bank: '',
      acc_number: '',
    },
    validationSchema: Yup.object({
      bank: Yup.string().required('Bank is required.'),
      acc_number: Yup.string()
        // .matches(bkAcc, 'The bk account format is not valid.')
        // .min(17, 'The bk account format is invalid')
        // .max(17, 'The bk account format is invalid.')
        .required('The account number is required.'),
    }),
    onSubmit: (values) => {
      mutate({ name: 'Bank', type: values.bank, bank_account_number: values.acc_number });
    },
  });

  return (
    <span>
      <FormLayout
        title="Setting payment accounts"
        description="From the select dropdown select a payment type and set up the account."
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-5">
            <Select
              id="bank"
              label="bank name"
              options={options}
              placeholder="Select bank..."
              onChange={(option: ISelectedValue) => formik.setFieldValue('bank', option.value)}
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.bank && formik.errors.bank ? formik.errors.bank : ''}
            />
          </div>
          <div className="w-1/2">
            <InputText
              id="acc_number"
              type="text"
              placeholder="Account number"
              onChange={formik.handleChange}
              value={formik.values.acc_number}
              onBlur={formik.handleBlur}
              label="bank account number"
              errorMsg={
                  formik.touched.acc_number
                  && formik.errors.acc_number
                    ? formik.errors.acc_number
                    : ''
                }
            />
          </div>
          <Button
            type="submit"
            className="mt-5"
            loading={isLoading}
          >
            Set account
          </Button>
        </form>
      </FormLayout>
    </span>
  );
};

export default PaymentAccount;
