/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { toast } from 'react-toastify';
import {
  Layout, Tabs, Content,
} from '../../../components';
import { menu } from '../local-helpers/MenuItems';
import { SchoolAdmin } from './AdminTab';
import { SystemAdmin } from './ShoolTab';
// import { System } from '../local-helpers/requests';

const CreateAccounts: React.FC = () => {
  React.useEffect(() => () => toast.dismiss(), []);

  return (
    <Layout menuItems={menu}>
      <Content title="Create Account">
        <Tabs>
          University
          <span>
            <SystemAdmin />
          </span>
          User
          <span>
            <SchoolAdmin />
          </span>
        </Tabs>
      </Content>
    </Layout>
  );
};

export { CreateAccounts };
