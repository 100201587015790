import React from 'react';
import { toast } from 'react-toastify';
import {
  Tabs, Layout, Content,
} from '../../../components';
import { useSchoolAdminMenu } from '../local-helpers/MenuItems';
import { useAccountantMenu } from '../../Accountant/MenuItems';
import { Role } from '../../../helpers';
import UploadStudents from './UploadStudents';
import Students from './Students';
import CampAndDept from './CampAndDept';
import { useCurrentUser } from '../../../contexts/UserContext';

const StudentManagement: React.FC = () => {
  const { user } = useCurrentUser();
  const schoolName = user?.schools?.[0]?.name;
  const isGasabo = schoolName?.includes('Gasabo');

  const menu = useSchoolAdminMenu();
  const accMenu = useAccountantMenu();

  React.useEffect(() => () => toast.dismiss(), []);

  return (
    <Layout menuItems={
      user?.role === Role.SCHOOL_ADMIN
        ? menu
        : user?.role === Role.ACCOUNTANT
          ? accMenu
          : []
}
    >
      <Content title={isGasabo ? 'Sellers Management' : 'Students Management'}>
        <Tabs>
          {isGasabo ? 'List of Sellers' : 'List of Students'}
          <span><Students /></span>
          {user?.role === Role.SCHOOL_ADMIN
            && (isGasabo ? 'Upload Sellers' : 'Upload Students')}
          {user?.role === Role.SCHOOL_ADMIN && <span><UploadStudents /></span>}
          {user?.role === Role.SCHOOL_ADMIN
            && (isGasabo ? 'Zones' : 'Campuses & Departments ')}
          <span><CampAndDept /></span>
        </Tabs>
      </Content>
    </Layout>
  );
};

export default StudentManagement;
