import React from 'react';
import { IoSadOutline } from 'react-icons/io5';
import Empty from '../assets/images/empty.svg';

const NoData: React.FC = () => (
  <div className="w-full h-full flex flex-col
         items-center justify-center py-16"
  >
    <div className="flex flex-col items-center">
      <img src={Empty} alt="empty" width="280" height="273.31" />
      <div className="text-gray flex mt-3 items-center">
        <h1 className="text-3xl mr-2 font-light">
          No data found!
          {' '}
        </h1>
        <IoSadOutline size={40} />
      </div>
    </div>

  </div>
);

export default NoData;
