/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { InputText, Card, Button } from '../components';
import Main from '../assets/images/main.svg';
import { ROUTE } from '../routes';
import { Role } from '../helpers';
import Urubuto from '../assets/images/Urubuto_Logo.svg';
import AuthLayout from '../components/Layout/AuthLayout';

interface ILogin {
  email: string;
  password: string;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

const loginUser = async (payload: ILogin): Promise<any> => {
  const response = await axios({
    method: 'POST',
    url: `${BASE_URL}/auth/login`,
    responseType: 'json',
    data: payload,
  });
  return response.data;
};

const Login: React.FC = () => {
  const {
    mutate, isLoading, data, isSuccess,
  } = useMutation(loginUser, {
    onError: () => {
      toast.error('Login failed. Please try again.');
    },
  });

  React.useEffect(() => () => toast.dismiss(), []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address.')
        .required('Email is required.'),
      password: Yup.string()
        // .min(6, 'Must be at least 6 characters.')
        .required('Password is required.'),
    }),
    onSubmit: (values) => {
      mutate(values);
    },
  });
  React.useEffect(() => {
    if (isSuccess) {
      sessionStorage.setItem('currentUser', JSON.stringify(data.data));
      switch (data.data.role) {
        case Role.ADMIN:
          window.location.href = NODE_ENV === 'staging' ? `/mis${ROUTE.ADMIN_TRANSACTIONS}` : `${ROUTE.ADMIN_TRANSACTIONS}`;
          break;
        case Role.SCHOOL_ADMIN:
          window.location.href = NODE_ENV === 'staging'
            ? `/mis${ROUTE.SCHOOL_ADMIN}`
            : `${ROUTE.SCHOOL_ADMIN}`;
          break;
        case Role.ACCOUNTANT:
          window.location.href = NODE_ENV === 'staging'
            ? `/mis${ROUTE.SCHOOL_ADMIN_STUDENTS}`
            : `${ROUTE.SCHOOL_ADMIN_STUDENTS}`;
          break;
        default:
          window.location.href = NODE_ENV === 'staging' ? `/mis${ROUTE.HOME}` : `${ROUTE.HOME}`;
          break;
      }
    }
  }, [isSuccess, data]);

  return (
    <AuthLayout>
      <p className="text-center text-2xl md:text-3xl text-blue my-4 md:my-6">Login.</p>
      <form className="flex flex-col pt-3" onSubmit={formik.handleSubmit}>
        <InputText
          type="email"
          label="email"
          id="email"
          placeholder="doe@bkth.rw"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          errorMsg={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : ''
                }
        />
        <InputText
          type="password"
          label="password"
          id="password"
          placeholder="*****"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          errorMsg={
                  formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : ''
                }
        />
        <Link to={ROUTE.FORGOT_PASSWORD} className="text-right font-light text-xs mt-3 mr-5 text-blue hover:underline">
          Forgot password?
        </Link>
        <Button
          type="submit"
          variant="primary"
          className="mt-6"
          loading={isLoading}
        >
          Login
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Login;
