/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { API } from '../../../helpers';

export interface ISchoolProfile {
  logo?: string;
  name?: string;
  abbreviation?: string;
  email?: string;
  address?: string;
  website?: string;
}

export interface IProfile {
  phone_number?: string,
  first_name?: string,
  last_name?: string,
  gender?: string,

}
export interface IChangePass {
  password: string;
}

const api = new API();

class Profile {
  updateUserInformation(schoolID: number, userId: number, body: IProfile): Promise<any> {
    return api.put(
      `/schools/${schoolID}/members/${userId}`,
      body,
      (res) => toast.success(res.message),
    );
  }

  updateSchoolInfo(schoolID: number, body: ISchoolProfile): Promise<any> {
    return api.put(`/schools/${schoolID}`,
      body,
      (res) => toast.success(res.message));
  }

  resetPassword(schoolID: number, userId: number): Promise<any> {
    return api.put(
      `/schools/${schoolID}/members/${userId}/reset_password`,
      {},
      (res) => toast.success(res.message),
    );
  }

  fetchCurrentUser = (): Promise<any> => api.get('/user', (res) => res)
}

export default Profile;
