import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSchoolAdminMenu } from './local-helpers/MenuItems';
import { useAccountantMenu } from '../Accountant/MenuItems';
import { Role } from '../../helpers';
import CustomContext from '../../contexts/CustomContext';
import School from './local-helpers/requests';
import { tableReducer } from '../../components/Table/TableReducer';
import {
  Layout,
  Content,
  Table,
  Button,
  Select,
  Badge,
} from '../../components';
import { convertJsonToXl, ReportHeaders } from '../../helpers/functions-and-objects';
import { useCurrentUser } from '../../contexts/UserContext';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const yearOptions = [
  { label: '2024', value: '2024' },
  { label: '2023', value: '2023' },
  { label: '2022', value: '2022' },
  { label: '2021', value: '2021' },
  // Add more years as needed
];

const monthOptions = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const statusOptions = [
  { label: 'All', value: '' },
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' },
  { label: 'Unpaid (Past Due)', value: 'unpaid (past due)' },
  { label: 'Partially Paid', value: 'partially paid' },
  { label: 'Overpaid', value: 'overpaid' },

];

const termOptions = [
  { label: '1st', value: '1' },
  { label: '2nd', value: '2' },
  { label: '3rd', value: '3' },
  { label: '4th', value: '4' },
  { label: '5th', value: '5' },
  { label: '6th', value: '6' },
  { label: '7th', value: '7' },
  { label: '8th', value: '8' },
  { label: '9th', value: '9' },
  { label: '10th', value: '10' },
  { label: '11th', value: '11' },
  { label: '12th', value: '12' },
];

const PaymentsReport: React.FC = () => {
  const { user } = useCurrentUser();
  const schoolId = user?.schools[0]?.id;
  const today = new Date();
  const currentMonth = today?.toLocaleString('lt-LT', { month: 'short' });
  const currentYear = today?.toLocaleString('lt-LT', { year: 'numeric' });
  const API = new School();
  const menu = useSchoolAdminMenu();
  const accMenu = useAccountantMenu();

  const [selectedYear, setSelectedYear] = React.useState(currentYear);
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonth.toString());
  const [selectedZone, setSelectedZone] = useState(0);
  const [searchString, setSearchString] = React.useState('');
  const [selectedTerm, setSelectedTerm] = React.useState('');
  const [selectedStatus, setSelectedStatus] = React.useState('');

  React.useEffect(() => () => toast.dismiss(), []);

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const { data: departmentsData, isSuccess: isDepartmentsSuccess } = useQuery(
    'departments',
    () => API.fetchDepartments(100, schoolId),
  );

  const zoneOptions = React.useMemo(() => {
    if (isDepartmentsSuccess && departmentsData) {
      return [
        { label: 'All', value: '' },
        ...departmentsData.data.map(
          (department: { id: string; name: string }) => ({
            label: department.name,
            value: department.id,
          }),
        ),
      ];
    }
    return [{ label: 'All', value: '' }];
  }, [departmentsData, isDepartmentsSuccess]);

  const columns = React.useMemo(
    () => [
      { Header: 'Transaction ID', accessor: 'transaction_id' },
      { Header: 'Stand No', accessor: 'stand_no' },
      { Header: 'Names', accessor: 'names' },
      { Header: 'Phone number', accessor: 'phone_number' },
      { Header: 'Zone', accessor: 'department_name' },
      { Header: 'Month paid', accessor: 'term' },
      { Header: 'Expected', accessor: 'expected_amount' },
      { Header: 'Fine', accessor: 'fine' },
      { Header: 'To be paid', accessor: 'amount_to_be_paid' },
      { Header: 'Paid', accessor: 'amount_paid' },
      { Header: 'Balance', accessor: 'remaining' },
      { Header: 'Paid on', accessor: 'created_at' },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          let statusText = '';
          let badgeColor = '';
          switch (original?.status) {
            case 'paid':
              statusText = 'paid';
              badgeColor = 'green';
              break;
            case 'partially paid':
              statusText = 'partially paid';
              badgeColor = 'yellow';
              break;
            case 'overpaid':
              statusText = 'overpaid';
              badgeColor = 'blue';
              break;
            case 'unpaid':
              statusText = 'unpaid';
              badgeColor = 'orange';
              break;
            case 'unpaid (past due)':
              statusText = 'unpaid (past due)';
              badgeColor = 'red';
              break;
            default:
              statusText = original?.status;
              badgeColor = 'red';
          }

          return (
            <Badge variant={badgeColor}>
              {statusText}
            </Badge>
          );
        },
      },
    ],
    [],
  );

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const { data, isLoading, isSuccess } = useQuery(
    [
      'transactions',
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      selectedYear,
      selectedMonth,
      selectedTerm,
      selectedStatus,
      selectedZone,
    ],
    () => API.fetchTransactionsReport(
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      selectedYear,
      selectedMonth,
      selectedTerm,
      selectedStatus,
      user.schools[0].id,
      selectedZone,
    ),
  );

  const {
    data: dtData,
    isSuccess: dtIsSuccess,
  } = useQuery(['download transactions',
    searchString,
    selectedYear,
    selectedMonth,
    selectedTerm,
    selectedStatus,
    selectedZone], () => API.downloadTransactionsReport(
    searchString,
    selectedYear,
    selectedMonth,
    selectedTerm,
    selectedStatus,
    user.schools[0].id,
    selectedZone,
  ));

  const exportReport = (): void => {
    if (dtIsSuccess) {
      const dataToDownload = dtData?.data?.map((t: Record<string, any>) => [
        t?.transaction_id,
        t?.stand_no,
        t?.names,
        t?.phone_number,
        t?.department_name,
        t?.term,
        t?.amount_to_be_paid,
        t?.expected_amount,
        t?.fine,
        t?.amount_paid,
        t?.remaining,
        t?.created_at,
        t?.status,
      ]);
      convertJsonToXl(dataToDownload, ReportHeaders, 'transactions');
    }
  };

  const isDisabled = selectedYear === ''
    || selectedMonth === '' || selectedTerm === '';
  const isDisabledMonth = selectedYear === '' || selectedMonth === '';

  return (
    <Layout
      menuItems={
        user?.role === Role.SCHOOL_ADMIN
          ? menu
          : user?.role === Role.ACCOUNTANT
            ? accMenu
            : []
      }
    >
      <Content title={`Payments Report - year: ${selectedYear || 'All'} - month:
         ${selectedMonth ? moment(selectedMonth).format('MMMM') : 'All'}`}
      >
        <CustomContext.Provider value={stateProvider}>
          <div className="w-full flex justify-between items-end flex-wrap">
            <div className="md:w-3/4 flex gap-2 flex-wrap mb-2 md:mb-0">
              <Select
                id="year-select"
                options={yearOptions}
                placeholder="Select Year"
                label="Year"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedYear(option.value as string)}
                // onBlur={() => { }}
                errorMsg=""
              />

              <Select
                id="month-select"
                options={monthOptions}
                placeholder="Select Month"
                label="Month"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedMonth(option.value as string)}
                // onBlur={() => { }}
                errorMsg=""
              // isDisabled={!selectedYear}
              />
              <Select
                id="month-paid-for"
                options={termOptions}
                placeholder="Select Months paid for..."
                label="Months paid for"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedTerm(option.value as string)}
                // onBlur={() => { }}
                errorMsg=""
                isDisabled={isDisabledMonth}
              />
              <Select
                id="zone-select"
                options={zoneOptions}
                placeholder="Select Zone"
                label="Zone"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedZone(Number(option.value))}
                // onBlur={() => { }}
                errorMsg=""
              />

              <Select
                id="status-select"
                options={statusOptions}
                placeholder="Select Status"
                label="Status"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedStatus(option.value as string)}
                // onBlur={() => { }}
                errorMsg=""
                isDisabled={isDisabled}
              />
            </div>
            <div className="md:w-1/4 justify-end pb-1">
              <Button type="button" onClick={exportReport}>
                Download Report
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            data={data?.data || []}
            meta={data?.meta}
            loading={isLoading}
            countPage={
              isSuccess
                ? Math.ceil(state.totalCount / state.queryPageSize)
                : undefined
            }
            searchLabel="search payments"
            onChangeCallback={(e: any) => handleSearch(e)}
            search
          />
        </CustomContext.Provider>
      </Content>
    </Layout>
  );
};

export default PaymentsReport;
