/* eslint-disable camelcase */
import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { formatYAxisValue, ITransactionData } from '../../helpers/functions-and-objects';

type Props = {
  data: ITransactionData[],
  xKey: string,
  areaKey?: string
}

const AreaChartCustom: React.FC<Props> = ({ data, xKey, areaKey = 'total_amount' }) => (
  <ResponsiveContainer
    width="100%"
    height="100%"
    style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
  >
    <AreaChart
      data={data}
      margin={{
        top: 10, right: 30, left: 0, bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#00d07c" stopOpacity={0.3} />
          <stop offset="95%" stopColor="#00d07c" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis dataKey={xKey} />
      <YAxis tickFormatter={formatYAxisValue} />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip formatter={(value) => `${value?.toLocaleString()} RWF`} />

      <Area
        type="monotone"
        dataKey={areaKey}
        stroke="#00d07c"
        fillOpacity={1}
        fill="url(#colorPv)"
      />
    </AreaChart>
  </ResponsiveContainer>
);

export default AreaChartCustom;
