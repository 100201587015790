import React from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { useComponentVisible } from '../hooks/useComponentVisible';
import { InputText, Button } from '.';

type Props = {
  timeFrame: (start: string, end: string) => void
}

const DateRange: React.FC<Props> = ({ timeFrame }) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const [from, setFrom] = React.useState('');
  const [to, setTo] = React.useState('');

  const handleFilters = (): void => {
    timeFrame(from, to);
  };

  let today: Date | string = new Date();
  let dd: string | number = today.getDate();
  let mm: string | number = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }

  today = `${yyyy}-${mm}-${dd}`;

  return (
    <div ref={ref} className="relative">
      <Button
        type="button"
        variant="tertiary"
        className="relative flex"
        onClick={() => setIsComponentVisible(true)}
        aria-label="dropdown"
      >
        <BiFilterAlt size={20} className="mr-1" />
        {' '}
        Date
      </Button>

      {isComponentVisible && (
        <ul
          className="absolute right-0 w-auto p-2 mt-2
                    space-y-2 text-black bg-white border
                    border-gray-light rounded-md shadow-md z-50"
        >
          <li className=" flex-col p-2">
            <InputText
              id="from-date"
              type="date"
              label="from"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFrom(e.target.value)}
              value={from}
              max={today}
            />
          </li>
          <li className="border-b border-gray-light flex flex-col p-2">
            <InputText
              id="from-date"
              type="date"
              label="to"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTo(e.target.value)}
              value={to}
              max={today}
            />
          </li>
          <Button type="button" onClick={handleFilters}>Apply</Button>
        </ul>
      )}
    </div>
  );
};

export default DateRange;
