import React from 'react';

type Props = {
  children: React.ReactNode;
};

const Card: React.FC<Props> = ({ children }: Props) => (
  <div
    className="w-full md:w-1/2 lg:w-1/3 p-2 md:p-10 bg-white rounded-md md:shadow"
  >
    {children}
  </div>
);

export default Card;
