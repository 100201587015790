/* eslint-disable react/react-in-jsx-scope */
import {
  BiPlus,
} from 'react-icons/bi';
import { HiOutlineUsers } from 'react-icons/hi';
import { IoSchoolOutline } from 'react-icons/io5';
import { MdPayment } from 'react-icons/md';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
// import { RiDashboardLine } from 'react-icons/ri';
import { ROUTE } from '../../../routes';

export const menu = [
  // {
  //   id: 1,
  //   icon: <RiDashboardLine color="#ffffff" />,
  //   menuName: 'Dashboard',
  //   path: ROUTE.ADMIN,
  // },
  {
    id: 2,
    icon: <CgArrowsExchangeAlt color="#ffffff" />,
    menuName: 'Transactions',
    path: ROUTE.ADMIN_TRANSACTIONS,
  },
  {
    id: 3,
    icon: <BiPlus color="#ffffff" />,
    menuName: 'Create Account',
    path: ROUTE.ADMIN_CREATE_ORG,
  },
  {
    id: 4,
    icon: <HiOutlineUsers color="#ffffff" />,
    menuName: 'Users',
    path: ROUTE.ADMIN_USERS,
  },
  {
    id: 5,
    icon: <IoSchoolOutline color="#ffffff" />,
    menuName: 'Universities',
    path: ROUTE.ADMIN_UNIVS,
  },
  {
    id: 6,
    icon: <MdPayment color="#ffffff" />,
    menuName: 'Payment Accounts',
    path: ROUTE.ADMIN_PAY_ACCOUNTS,
  },
  // {
  //   id: 5,
  //   icon: <BiCog color="#ffffff" />,
  //   menuName: 'Settings',
  //   path: ROUTE.ADMIN_SETTINGS,
  // },
];
