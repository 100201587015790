export const customStyles = {
  control: (base: any, state: any): any => ({
    ...base,
    border: 'solid 1px #e3e8f0',
    background: state.isDisabled ? '#f7f8fb' : '#f0f2f7',
    borderRadius: '10px',
    padding: '4px 0px',
    cursor: state.isDisabled ? 'crosshair' : 'pointer',
    boxShadow: state.isFocused ? '0px 8px 10px #e3e8f0' : 0,
    '&:hover': {
      border: 'solid 1px #e3e8f0',
    },
    outline: 'none',
    caretColor: 'transparent',
  }),
  indicatorSeparator: (base: any): any => ({
    ...base,
    display: 'none',
  }),
  placeholder: (base: any, state: any): any => ({
    ...base,
    color: state.isDisabled ? '#b0b5bd' : '#606770',
  }),
};
