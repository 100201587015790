import React from 'react';
import {
  PieChart, Pie, Cell, Legend, Tooltip,
} from 'recharts';
import { Empty } from '..';
import PieSkeleton from '../Skeletons/Pie';

type Props = {
  data?: Record<string, any>[],
  colors?: string[],
  pieKey?: string,
  loading?: boolean
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Piechart: React.FC<Props> = ({
  data, colors = COLORS, pieKey = 'value', loading,
}) => {
  if (loading) return <PieSkeleton />;
  if (data !== undefined && data.length === 0) return <Empty />;
  return (
    <PieChart width={680} height={400}>
      <Pie
        dataKey={pieKey}
        isAnimationActive={false}
        data={data}
        cx="50%"
        cy="50%"
        outerRadius={80}
        label
      >
        {data !== undefined && data.map((entry, index) => (
          <Cell
            // eslint-disable-next-line react/no-array-index-key
            key={`cell-${index}`}
            fill={colors[index % colors.length]}
          />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

export default Piechart;
