import React from 'react';
import { toast } from 'react-toastify';
import {
  Layout, Content,
} from '../../components';
import { useSchoolAdminMenu } from '../School/local-helpers/MenuItems';
import { menu as adminSideMenu } from '../Admin/local-helpers/MenuItems';
import {
  subMenuProfile as schoolSubmenu,
} from '../School/local-helpers/SubMenuProfile';
import { subMenuProfile as adminSubmenu } from '../Admin/local-helpers/SubMenuProfile';
import { useAccountantMenu } from '../Accountant/MenuItems';
import { subMenuProfile as accSubMenu } from '../Accountant/SubMenuProfile';
import { Role } from '../../helpers';
import { SchoolProfile } from './SchoolProfile';
import AdminProfile from './AdminProfile';
import { useCurrentUser } from '../../contexts/UserContext';

interface ISideObject {
  id: number;
  icon: React.ReactNode;
  menuName: string;
  path: string;
}

interface IDropdownObject {
  id: string;
  path: string;
  icon: React.ReactNode;
  title: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

interface IDropdownMenu {
  menu: IDropdownObject[];
}
interface ISideMenu {
  menuItems: ISideObject[];
}

const UserProfile: React.FC = () => {
  const { user } = useCurrentUser();
  const role = user?.role;
  const accMenu = useAccountantMenu();
  const schoolSideMenu = useSchoolAdminMenu();

  const [sideMenu, setSideMenu] = React.useState<ISideMenu>({ menuItems: [] });
  const [, setProfileSubmenu] = React.useState<IDropdownMenu>({
    menu: [],
  });

  React.useEffect(() => {
    switch (role) {
      case Role.SCHOOL_ADMIN:
        setSideMenu({ menuItems: schoolSideMenu });
        setProfileSubmenu({ menu: schoolSubmenu });
        break;

      case Role.ADMIN:
        setSideMenu({ menuItems: adminSideMenu });
        setProfileSubmenu({ menu: adminSubmenu });
        break;
      case Role.ACCOUNTANT:
        setSideMenu({ menuItems: accMenu });
        setProfileSubmenu({ menu: accSubMenu });
        break;
      default:
    }
    return () => {
      setSideMenu({ menuItems: [] });
      setProfileSubmenu({ menu: [] });
      toast.dismiss();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <Layout menuItems={sideMenu.menuItems}>
      {/* <Header /> */}

      <Content title={`${role?.split('_')?.join(' ')} Profile`}>
        {role === Role.SCHOOL_ADMIN && <SchoolProfile />}
        {role === Role.ADMIN && <AdminProfile />}
        {role === Role.ACCOUNTANT && <SchoolProfile />}
      </Content>
    </Layout>
  );
};

export { UserProfile };
