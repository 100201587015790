import React from 'react';
import { BiUser, BiCog, BiLogOutCircle } from 'react-icons/bi';
import { API } from '../../../helpers';
import { ROUTE } from '../../../routes';

const api = new API();

function logout(): void {
  api.logout();
}

export const subMenuProfile = [{
  id: '1',
  path: ROUTE.USER_PROFILE,
  icon: <BiUser className="w-4 h-4 mr-3" />,
  title: 'Profile',
},
{
  id: '2',
  path: ROUTE.SCHOOL_ADMIN_SETTINGS,
  icon: <BiCog className="w-4 h-4 mr-3" />,
  title: 'Settings',
}, {
  id: '3',
  path: ROUTE.HOME,
  icon: <BiLogOutCircle className="w-4 h-4 mr-3" />,
  title: 'Logout',
  onClick: (): void => logout(),
}];
