import React from 'react';
import { RxHamburgerMenu, RxCross2 } from 'react-icons/rx';
import { Dropdown } from '..';
import { Role } from '../../helpers';
import { subMenuProfile as adminSubMenu } from '../../pages/Admin/local-helpers/SubMenuProfile';
import { subMenuProfile as schoolSubMenu } from '../../pages/School/local-helpers/SubMenuProfile';
import { subMenuProfile as accSubMenu } from '../../pages/Accountant/SubMenuProfile';
import { useCurrentUser } from '../../contexts/UserContext';

type HeaderProps = {
  toggleSidebar: () => void;
  isOpen: boolean;
};

const Header: React.FC<HeaderProps> = ({ toggleSidebar, isOpen }) => {
  const { user } = useCurrentUser();

  return (
    <header className="z-20 py-4 bg-white border-b border-gray-light">
      <div className="container flex items-center justify-between md:justify-end h-full
      px-4 md:px-6 mx-auto"
      >
        {/* Mobile hamburger */}
        <button
          type="button"
          className="p-1 -ml-1 mr-5 rounded-md md:hidden focus:outline-none
          focus:shadow-outline-green"
          aria-label="hamburger menu"
          onClick={toggleSidebar}
        >
          {isOpen ? <RxCross2 className="w-6 h-6" />
            : <RxHamburgerMenu className="w-6 h-6" />}
        </button>

        <ul className="flex items-center space-x-4 md:space-x-6 flex-grow md:flex-grow-0">
          {/* Admin Dropdown */}
          {user?.role === Role.ADMIN && (
            <Dropdown menu={adminSubMenu} currentUser={user}>
              <span className="text-xs uppercase font-semibold hidden md:block">
                Administrator
              </span>
            </Dropdown>
          )}
          {/* School Admin Dropdown */}
          {user?.role === Role.SCHOOL_ADMIN && (
            <Dropdown menu={schoolSubMenu} currentUser={user}>
              <div className="w-8 h-8 rounded-full border-2 border-white bg-yellow
               text-yellow-light shadow flex items-center justify-center font-bold"
              >
                {user?.schools[0]?.name?.[0]}
              </div>
            </Dropdown>
          )}
          {/* Accountant Dropdown */}
          {user?.role === Role.ACCOUNTANT && (
            <Dropdown menu={accSubMenu} currentUser={user}>
              <div className="w-8 h-8 rounded-full border-2 border-white
               bg-yellow text-yellow-light shadow
              flex items-center justify-center font-bold"
              >
                {user?.schools[0]?.name?.[0]}
              </div>
            </Dropdown>
          )}
        </ul>
      </div>
    </header>
  );
};

export default Header;
