interface IPagination {
  queryPageIndex: number;
  queryPageSize: number;
  totalCount: number;
}

// enum EPagination {
//    queryPageIndex = 0,
//   queryPageSize = 0,
//   totalCount = 0,
// }

enum EActions {
  PAGE_CHANGED = 'PAGE_CHANGED',
  PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED',
  TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED',
}
interface IAction {
  type: EActions;
  payload: IPagination;
}

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

export const tableReducer = (
  state: IPagination,
  { type, payload }: IAction,
): any => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
