import React from 'react';
import {
  Button,
} from '../../components';
import { ROUTE } from '../../routes';

const NODE_ENV = process.env.REACT_APP_NODE_ENV;

const PasswordResetSchool: React.FC = () => {
  const redirectUrl = NODE_ENV === 'staging'
    ? `/mis${ROUTE.RESET_PASSWORD}`
    : `${ROUTE.RESET_PASSWORD}`;

  const handleResetPassword = (): void => {
    window.location.href = redirectUrl;
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <Button
        type="button"
        variant="primary"
        onClick={handleResetPassword}
      >
        Reset Password
      </Button>
    </div>
  );
};

export { PasswordResetSchool };
