import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  RouteComponentProps,
} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { ROUTE } from './routes';
import { useCurrentUser } from './contexts/UserContext';

interface Props {
  component: React.ElementType;
  roles: string[];
  path?: string;
  exact?: boolean;
}

const ProtectedRoute: React.FC<Props> = ({
  component: Component,
  roles,
  exact = false,
  ...rest
}: Props) => {
  const { user } = useCurrentUser();

  return (
    <Route
      {...rest}
      exact={exact}
      render={(props: RouteComponentProps<any>): any => {
        if (isEmpty(user)) {
          // not logged in so redirect to login page with the return url
          return (
            <Switch>
              <Redirect
                to={{
                  pathname: ROUTE.HOME,
                  state: {
                    from: props.location,
                  },
                }}
              />
            </Switch>
          );
        }
        // check if route is restricted by role
        // if ((roles && roles.indexOf(currentUser.role) === -1) === false) {

        if (!roles?.includes(user?.role)) {
          return <Redirect to={{ pathname: ROUTE.HOME, state: { from: props.location } }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
