import React from 'react';
import Urubuto from '../../assets/images/Urubuto_Logo.svg';
import Card from '../Card';

type Props = {
    navLinks?: React.ReactNode
    children: React.ReactNode
}

const AuthLayout: React.FC<Props> = ({ navLinks, children }) => (
  <main>
    {/* Navbar */}
    <nav className="fixed top-0 left-0 w-full flex justify-between p-4 md:p-6 bg-blue ">
      <div>
        <img src={Urubuto} alt="Urubuto logo" className="w-36 md:w-[173.07px]" />
      </div>
      {navLinks}
    </nav>

    {/* Card section */}
    <div className="w-full flex justify-center items-center bg-white md:bg-blue h-screen p-4">
      <Card>
        <div className="flex flex-col justify-center p-4 md:p-6">
          {children}
        </div>
      </Card>
    </div>
  </main>
);

export default AuthLayout;
