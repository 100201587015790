/* eslint-disable camelcase */
import React from 'react';

interface School {
  id: string;
  user_id: string;
  name: string;
  address: string;
  logo: string | null;
  category: string | null;
  status: string;
  abbreviation: string;
  website: string;
  email: string;
  created_at: string;
  updated_at: string;
}

interface User {
  id: string;
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  status: string;
  role: string;
  external_user_id: string;
  gender: string;
  registration_number: string;
  username: string;
  birth_date: string | null;
  token: string;
  schools: School[];
}

// Define the context value
interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
}

const UserContext = React.createContext<UserContextType | undefined>(undefined);

const getInitialState = (): User | null => {
  const currentUser = sessionStorage.getItem('currentUser');
  return currentUser ? JSON.parse(currentUser) : null;
};

export const useCurrentUser = (): any => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useCurrentUser must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps): any => {
  const [user, setUser] = React.useState(getInitialState);

  // React.useEffect(() => {
  //   const storedUser = sessionStorage.getItem('currentUser');
  //   if (storedUser) {
  //     setUser(JSON.parse(storedUser));
  //   }
  // }, []);

  React.useEffect(() => {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
