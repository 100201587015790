import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { TbPercentage } from 'react-icons/tb';
import { FaHands } from 'react-icons/fa';
import { MdNumbers } from 'react-icons/md';
import {
  InputText,
  Button,
  FormLayout,
  Select,
  StatCards,
} from '../../../components';
import { ISelectedValue } from '../../../components/Select';
import Profile from '../../Profile/local-helpers/requests';
import { useCurrentUser } from '../../../contexts/UserContext';

interface IStats {
  id: number;
  description: string;
  numbers: string;
  icon: React.ReactNode;
  iconBgColor: string;
}

interface IStats {
  id: number;
  description: string;
  numbers: string;
  icon: React.ReactNode;
  iconBgColor: string;
}

const categories = [{
  label: 'Percentage',
  value: 'PERCENTAGE',
}, {
  label: 'Fixed amount',
  value: 'FIXED',
}];

const Fines: React.FC = () => {
  const { user } = useCurrentUser();
  const school = user?.schools[0];
  const API = new Profile();
  const [statistics, setStatistics] = React.useState<IStats[]>([]);

  const { refetch } = useQuery(
    ['current user'],
    () => API.fetchCurrentUser(),
    {
      onSuccess(data) {
        setStatistics([]);

        if (data?.data?.schools[0]?.fine_type) {
          setStatistics((prev) => ([
            ...prev,
            {
              id: 1,
              description: 'Fine type',
              numbers: data?.data?.schools[0]?.fine_type,
              icon: <TbPercentage color="#FF993D" className="w-5 h-5" />,
              iconBgColor: 'bg-orange-light',
            },
          ]));
        }

        if (data?.data?.schools[0]?.fine_value) {
          setStatistics((prev) => ([
            ...prev,
            {
              id: 2,
              description: 'Fine value',
              numbers: data?.data?.schools[0]?.fine_value.toLocaleString(),
              icon: <MdNumbers color="#7D40E7" className="w-5 h-5" />,
              iconBgColor: 'bg-purple-light',
            },
          ]));
        }

        if (data?.data?.schools[0]?.grace_period) {
          setStatistics((prev) => ([
            ...prev,
            {
              id: 3,
              description: 'Grace period days',
              numbers: data?.data?.schools[0]?.grace_period,
              icon: <FaHands color="#4186e0" className="w-5 h-5" />,
              iconBgColor: 'bg-blue-alert',
            },
          ]));
        }
      },
    },
  );

  const {
    mutate,
    isLoading,
  } = useMutation((payload: Record<string, any>) => API.updateSchoolInfo(school?.id, payload), {
    onSuccess() {
      refetch();
    },
  });

  const formik = useFormik({
    initialValues: {
      fine_type: '',
      fine_value: '',
      grace_period: '',
    },
    validationSchema: Yup.object({
      fine_type: Yup.string()
        .required('Fine type is required.'),
      fine_value: Yup.number()
        .required('Fine value is required.')
        .min(1, 'The value should not be less than 1')
        .when('fine_type', {
          is: 'PERCENTAGE',
          then: Yup.number()
            .max(100, 'Percentage value should not exceed 100'),
        }),
      grace_period: Yup.number()
        .required('Grace period is required.')
        .min(1, 'The grace period should not be less than 1'),
    }),
    onSubmit: (values) => {
      mutate(values);
      formik.resetForm();
    },
  });

  return (
    <span>
      <h3 className="text-lg font-medium leading-6 text-black mb-1">Existing settings</h3>
      <StatCards stats={statistics} />

      <FormLayout
        title="Setting fines"
        description="Set the fine functionality."
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-5">
            <Select
              id="fine_type"
              placeholder="Select fine type..."
              label="Fine type"
              onChange={(option: ISelectedValue) => {
                formik.setFieldValue('fine_type', option.value);
              }}
              onBlur={formik.handleBlur}
              options={categories}
              errorMsg={
                  formik.touched.fine_type && formik.errors.fine_type
                    ? formik.errors.fine_type
                    : ''
                          }
            />
            <InputText
              id="fine_value"
              label="Fine value"
              placeholder="00.0"
              value={formik.values.fine_value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={
                  formik.touched.fine_value && formik.errors.fine_value
                    ? formik.errors.fine_value
                    : ''
                          }
            />

            <InputText
              id="grace_period"
              label="Grace period in days"
              placeholder="0"
              value={formik.values.grace_period}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={
                  formik.touched.grace_period && formik.errors.grace_period
                    ? formik.errors.grace_period
                    : ''
                          }
            />
          </div>
          <div className="mt-2 flex w-full justify-end">
            <Button
              type="button"
              variant="secondary"
              onClick={() => formik.resetForm()}
            >
              Cancel
            </Button>
            <Button type="submit" className="ml-2" loading={isLoading}>
              Set Fine settings
            </Button>
          </div>
        </form>
      </FormLayout>
    </span>
  );
};

export default Fines;
