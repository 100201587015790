import React, { useState, useEffect } from 'react';
import Urubuto from '../../assets/images/Urubuto_Logo.svg';
import Menu from './Menu';
import Header from './Header';

type Props = {
  children: React.ReactNode,
  menuItems: {
    id: number,
    icon: React.ReactNode,
    menuName: string,
    path: string
  }[]
};

const Layout: React.FC<Props> = ({ children, menuItems }: Props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (): void => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = (): void => {
    setIsSidebarOpen(false);
  };

  // Handle keyboard accessibility for closing sidebar on 'Escape' key press
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && isSidebarOpen) {
        closeSidebar();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isSidebarOpen]);

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Desktop sidebar */}
      <aside className="z-20 hidden w-64 overflow-y-auto overflow-x-hidden
       bg-blue md:block flex-shrink-0"
      >
        <div className="text-gray-500 dark:text-gray-400">
          <div className="w-full text-lg font-bold text-white pt-5 pl-6">
            <img src={Urubuto} alt="" width="173.07" height="50" />
          </div>
          <ul className="mt-10">
            {menuItems.map(({
              id, menuName, path, icon,
            }) => (
              <Menu key={id} name={menuName} path={path} icon={icon} />
            ))}
          </ul>
        </div>
      </aside>

      {/* Mobile sidebar backdrop */}
      <div
        className={`fixed inset-0 z-20 flex items-end bg-black bg-opacity-50 
          sm:items-center sm:justify-center ${isSidebarOpen ? 'block' : 'hidden'
        } md:hidden`}
        onClick={closeSidebar}
        role="button"
        tabIndex={0} // Makes the backdrop focusable and keyboard-interactive
        aria-label="Close sidebar"
        onKeyDown={(e) => e.key === 'Enter' && closeSidebar()} // Handle Enter key for closing
      />

      {/* Mobile sidebar */}
      <aside
        className={`fixed inset-y-0 z-20 w-64 bg-blue text-white overflow-y-auto transform 
          ${isSidebarOpen ? 'translate-x-0 mt-10' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out md:hidden`}
      >
        <div className="py-4 text-gray-500 dark:text-gray-400">
          <div className="w-full text-lg font-bold pt-5 pl-6">
            <img src={Urubuto} alt="Logo" width="173.07" height="50" />
          </div>
          <ul className="mt-10">
            {menuItems.map(({
              id, menuName, path, icon,
            }) => (
              <Menu key={id} name={menuName} path={path} icon={icon} />
            ))}
          </ul>
        </div>
      </aside>

      {/* Pass toggleSidebar as a prop to the Header component */}
      <div className="flex flex-col flex-1">
        <Header toggleSidebar={toggleSidebar} isOpen={isSidebarOpen} />
        {children}
      </div>
    </div>
  );
};

export default Layout;
