import React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  icon: React.ReactNode,
  path: string,
  name: string,
}

const Menu: React.FC<Props> = ({
  icon, path, name,
}: Props) => (

  <li>
    <NavLink
      to={path}
      className="relative px-6 py-3 ml-4 inline-flex
      items-center w-full text-blue-alert text-sm font-semibold hover:text-blue-200"
      activeClassName=" bg-blue-500 rounded-l"
      exact
    >
      <span className="inline-flex items-center w-full text-sm font-semibold">
        {icon}
        <span className="ml-4">{name}</span>
      </span>
    </NavLink>

  </li>
);

export default Menu;
