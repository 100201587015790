import React from 'react';
import { Link } from 'react-router-dom';
import { useComponentVisible } from '../../hooks/useComponentVisible';

type Props = {
  children: React.ReactNode;
  menu: {
    id: string,
    path: string,
    icon: React.ReactNode,
    title: string,
    onClick?: React.MouseEventHandler<HTMLAnchorElement>,

  }[];
  currentUser?: Record<string, any>
};

interface IDetails {
  fname: string,
  lname: string,
  email: string
}

const DropdownMenu: React.FC<Props> = ({ children, menu, currentUser }: Props) => {
  const [details, setDetails] = React.useState<IDetails>({ fname: '', lname: '', email: '' });

  React.useEffect(() => {
    setDetails({
      fname: currentUser?.first_name,
      lname: currentUser?.last_name,
      email: currentUser?.email,
    });
  }, [currentUser]);
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  return (
    <li ref={ref} className="relative">
      <button
        type="button"
        className="relative align-middle rounded
                  focus:shadow-outline-green focus:outline-none"
        onClick={() => setIsComponentVisible(true)}
        aria-label="dropdown"
      >
        {children}
      </button>
      {isComponentVisible && (
        <ul
          className="absolute right-0 w-56 p-2 mt-2
                    space-y-2 text-black bg-white border
                    border-gray-light rounded-md shadow-md z-50"
        >
          {details?.email !== undefined && (
          <li className="border-b border-gray-light flex flex-col p-2">
            <span className="text-xs font-semibold ">
              {details?.fname}
              {' '}
              {details?.lname}
            </span>
            <span className="text-xs">{details?.email}</span>
          </li>
          )}
          {menu.map(({
            id, icon, title, onClick, path,
          }) => (
            <li className="flex" key={id}>
              <Link
                to={path}
                type="button"
                onClick={onClick}
                className="inline-flex items-center w-full
                      px-2 py-1 text-sm font-semibold transition-colors
                      duration-150 rounded-md hover:bg-gray-light
                      cursor-pointer focus:bg-gray-light"
              >
                {icon}
                <span>{title}</span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default DropdownMenu;
