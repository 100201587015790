/* eslint-disable no-self-assign */
import React from 'react';
// import { SiCashapp } from 'react-icons/si';
// import { AiOutlineNumber } from 'react-icons/ai';
// import { VscLoading } from 'react-icons/vsc';
// import { IoIosPeople } from 'react-icons/io';
// import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
// import Keys from 'lodash/keys';
import {
  Layout, StatCards, Content, LineChart, PieChart,
} from '../../components';
import { menu } from './local-helpers/MenuItems';
// import { System } from './local-helpers/requests';

// const system = new System();

interface IStats {
  id: number,
  description: string,
  numbers: string,
  icon: React.ReactNode,
  iconBgColor: string
}

const Admin: React.FC = () => {
  const statistics: IStats[] = [];
  const transactionsChartData: Record<string, any>[] = [];
  const channelChartData: Record<string, any>[] = [];
  const serviceChartData: Record<string, any>[] = [];

  const COLORS = ['#E0E3B2', '#003049', '#FFDA1E', '#FF0018'];
  const COLORSS = ['#00C49F', '#0088FE', '#FFBB28', '#FF8042'];

  // const { data, isSuccess, isLoading } = useQuery('stats', () => system.getStats());

  React.useEffect(() => () => toast.dismiss(), []);

  // if (isSuccess) {
  //   Keys(data?.data).map((key, i) => {
  //     switch (key) {
  //       case 'users_count':
  //         statistics = [...statistics, {
  //           id: i,
  //           description: 'Users',
  //           numbers: data?.data?.users_count.toLocaleString(),
  //           icon: <IoIosPeople color="#4186e0" className="w-5 h-5" />,
  //           iconBgColor: 'bg-blue-alert',
  //         }];
  //         break;
  //       case 'transactions_count':
  //         statistics = [...statistics, {
  //           id: i,
  //           description: 'Number of Transactions',
  //           numbers: data?.data?.transactions_count.toLocaleString(),
  //           icon: <AiOutlineNumber color="#ffb41d" className="w-5 h-5" />,
  //           iconBgColor: 'bg-yellow-light',
  //         }];
  //         break;
  //       case 'transactions_total':
  //         statistics = [...statistics, {
  //           id: i,
  //           description: 'Transactions Value',
  //           numbers: `${data?.data?.transactions_total.toLocaleString()} RWF`,
  //           icon: <SiCashapp color="#00d07c" className="w-5 h-5" />,
  //           iconBgColor: 'bg-blue-alert',
  //         }];
  //         break;
  //       case 'pending_accounts_count':
  //         statistics = [...statistics, {
  //           id: i,
  //           description: 'Pending Approvals',
  //           numbers: data?.data?.pending_accounts_count.toLocaleString(),
  //           icon: <VscLoading color="#ff3062" className="w-5 h-5" />,
  //           iconBgColor: 'bg-red-alert',
  //         }];
  //         break;
  //       case 'transactions_per_month':
  //         if (data?.data?.transactions_per_month !== undefined) {
  //           [...data?.data?.transactions_per_month].map((t) => {
  //             transactionsChartData = [
  //               ...transactionsChartData,
  //               t,
  //             ];
  //             return transactionsChartData;
  //           });
  //         }
  //         break;
  //       case 'transactions_per_channel':
  //         if (data?.data?.transactions_per_channel !== null) {
  //           [...data?.data?.transactions_per_channel].map((c) => {
  //             channelChartData = [
  //               ...channelChartData,
  //               {
  //                 name: c.payment_channel_name,
  //                 number: parseInt(c.sum, 10),
  //               },
  //             ];
  //             return channelChartData;
  //           });
  //         } else return [];
  //         break;
  //       case 'transactions_per_service':
  //         [...data?.data?.transactions_per_service].map((s) => {
  //           serviceChartData = [
  //             ...serviceChartData,
  //             {
  //               name: s.service_name,
  //               number: parseInt(s.sum, 10),
  //             },
  //           ];
  //           return serviceChartData;
  //         });
  //         break;
  //       default:
  //         break;
  //     }
  //     return null;
  //   });
  // }

  return (
    <Layout menuItems={menu}>
      <Content title="Dashboard">
        <StatCards stats={statistics} />
        <div className="grid grid-cols-2 gap-5">
          <div
            className="flex items-center p-4 bg-white
      rounded-lg border-gray-bg border shadow-xs dark:bg-gray-800"
          >
            <div>
              <p className="mb-5 text-sm font-medium
          text-gray-600 dark:text-gray-400"
              >
                Total Payments
              </p>
              <LineChart
                data={transactionsChartData}
                lineKey="sum"
                xAxisKey="month"
                // loading={isLoading}
              />
            </div>
          </div>
          <div
            className="flex items-center p-4 bg-white
      rounded-lg border-gray-bg border shadow-xs dark:bg-gray-800"
          >
            <div>
              <p className="mb-5 text-sm font-medium
          text-gray-600 dark:text-gray-400"
              >
                Distribution of payment channels
              </p>
              <PieChart
                data={channelChartData}
                pieKey="number"
                colors={COLORS}
                // loading={isLoading}
              />
            </div>
          </div>
          <div
            className="flex items-center p-4 bg-white
      rounded-lg border-gray-bg border shadow-xs dark:bg-gray-800"
          >
            <div>
              <p className="mb-5 text-sm font-medium
          text-gray-600 dark:text-gray-400"
              >
                Distribution of transactions by service
              </p>
              <PieChart
                data={serviceChartData}
                pieKey="number"
                colors={COLORSS}
                // loading={isLoading}
              />
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Admin;
