/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Tooltip from 'react-tooltip';
import Moment from 'moment';
import { useQuery } from 'react-query';
import { BiChevronsRight, BiChevronsDown } from 'react-icons/bi';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { Table, SubRows } from '../../../components';
import { tableReducer } from '../../../components/Table/TableReducer';
import { getDepartByCampus } from '../../../helpers/functions-and-objects';
import CustomContext from '../../../contexts/CustomContext';
import School from '../local-helpers/requests';
import { useCurrentUser } from '../../../contexts/UserContext';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const CampAndDept: React.FC = () => {
  const { user } = useCurrentUser();
  const schoolId = user?.schools[0]?.id;

  const [campuses, setCampuses] = React.useState<Array<Record<string, any>>>([]);
  const [departments, setDepartments] = React.useState<Array<Record<string, any>>>([]);
  const [filteredDepts, setFilteredDepts] = React.useState<Array<Record<string, any>>>([]);
  const [campusMeta, setCampusMeta] = React.useState({});
  const API = new School();

  const { isLoading: isCampusFetching } = useQuery('campuses', () => API.fetchCampuses(schoolId), {
    onSuccess(data) {
      const { data: campusData, meta } = data;
      setCampuses(campusData);
      setCampusMeta(meta);
    },
    retry: false,
  });

  const { isLoading: isDepartmentFetching } = useQuery('departments', () => API.fetchDepartments(
    100,
    schoolId,
  ), {
    retry: false,
    // enabled: selectedCampus.trim().length > 0,
    onSuccess(data) {
      const { data: deptData } = data;
      setDepartments(deptData);
    },
  });

  const showDepartment = React.useCallback((id: string) => {
    const d = getDepartByCampus(id, departments || []);
    setFilteredDepts(d);
  }, [departments]);

  const columns = React.useMemo(() => [
    {
      Header: '',
      id: 'expander',
      Cell: ({ row }: Record<string, any>) => (

        <>
          <span
            data-tip
            data-for="depts"
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
              onClick: () => {
                row.toggleRowExpanded();
                showDepartment(row?.original?.id);
              },
            })}
          >
            {row.isExpanded ? <BiChevronsDown size={18} /> : <BiChevronsRight size={18} />}
          </span>
          <Tooltip id="depts" type="light">View departments</Tooltip>
        </>
      ),
      SubCell: () => null,
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Address',
      accessor: 'address',
    }, {
      Header: 'Created at',
      accessor: (row: Record<string, any>) => [row?.created_at],
      Cell: ({
        row:
            { original },
      }: Record<string, any>): React.ReactNode => Moment(original?.created_at)
        .format('DD/MM/YYYY, h:mm:ss a') || 'created at',
    },
    {
      Header: 'Updated at',
      accessor: (row: Record<string, any>) => [row?.updated_at],
      Cell: ({
        row:
            { original },
      }: Record<string, any>): React.ReactNode => Moment(original?.updated_at)
        .format('DD/MM/YYYY, h:mm:ss a') || 'updated at',
    },
  ], [showDepartment]);

  const renderDepartments = React.useCallback(({ row, rowProps, visibleColumns }) => (
    <SubRows
      row={row}
      rowProps={rowProps}
      visibleColumns={visibleColumns}
      data={filteredDepts}
      loading={isDepartmentFetching}
    />
  ), [isDepartmentFetching, filteredDepts]);

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  return (
    <>
      <span className="mt-6 text-sm flex italic text-black">
        <HiOutlineLightBulb className="mb-1" size={24} />
        {' '}
        Tip: Click on the arrows to expand and view departments.
      </span>
      <CustomContext.Provider value={stateProvider}>
        <Table
          columns={columns}
          data={campuses || []}
          searchLabel="Search campus"
          renderSubRow={renderDepartments}
          loading={isCampusFetching}
          meta={campusMeta || []}
          countPage={
              campuses
                ? Math.ceil(state.totalCount / state.queryPageSize)
                : undefined
            }
        />
      </CustomContext.Provider>

    </>
  );
};

export default CampAndDept;
