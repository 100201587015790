import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { FaUserEdit } from 'react-icons/fa';
import Moment from 'moment';
import Tooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import {
  Table, Layout, Content, Button,
} from '../../components';
import CustomContext from '../../contexts/CustomContext';
import { tableReducer } from '../../components/Table/TableReducer';
import School from './local-helpers/requests';
import { useSchoolAdminMenu } from './local-helpers/MenuItems';
import { Role } from '../../helpers';
import UpdateService from './UpdateService';
import { useCurrentUser } from '../../contexts/UserContext';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const Services: React.FC = () => {
  const { user } = useCurrentUser();
  const API = React.useMemo(() => new School(), []);
  const menu = useSchoolAdminMenu();
  const [isEdit, setIsEdit] = React.useState(false);
  const [serviceInfo, setServiceInfo] = React.useState<Record<string, any>>({});
  const [searchString, setSearchString] = React.useState('');

  React.useEffect(() => () => toast.dismiss(), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Service ID',
        accessor: 'id',
      },
      {
        Header: 'Service name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Created at',
        accessor: (row: Record<string, any>) => row?.created_at,
        // eslint-disable-next-line max-len
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => Moment(original?.created_at).format('DD/MM/YYYY, h:mm:ss a')
          || 'created at',
      },
      {
        Header: 'Updated at',
        accessor: (row: Record<string, any>) => row?.updated_at,
        // eslint-disable-next-line max-len
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => Moment(original?.updated_at).format('DD/MM/YYYY, h:mm:ss a')
          || 'updated at',
      },
      user?.role === Role.SCHOOL_ADMIN
        ? {
          Header: 'Edit',
          accessor: 'edit',
          Cell: ({
            row: { original },
          }: Record<string, any>): React.ReactNode => (
            <Button
              type="button"
              variant="tertiary"
              size="small"
              onClick={() => {
                setIsEdit(true);
                setServiceInfo(original);
              }}
            >
              <FaUserEdit size={18} />
            </Button>
          ),
        }
        : { id: 'no-edit-allowed', Cell: () => '' },
      {
        Header: 'Status',
        accessor: (row: any) => row?.status,
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          const {
            mutate,
            isLoading,
          } = useMutation((payload: any) => API.changeFeeStatus(payload, user.schools[0].id));
          return (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  return user?.role === Role.SCHOOL_ADMIN
                    ? mutate({
                      action:
                          original?.status === 'active'
                            ? 'deactivate'
                            : 'activate',
                      fee_id: original?.id,
                    })
                    : () => null;
                }}
              >
                <button
                  type="submit"
                  data-tip
                  data-for="status-change"
                  className={`text-xs px-2 font-medium
                ${
                  original?.status === 'active'
                    ? 'text-green-alertText bg-green-alertText'
                    : 'text-red-alertText bg-red-alertText'
                }
                  bg-opacity-10 rounded py-0.5 mr-1`}
                >
                  {isLoading ? 'changing...' : original?.status}
                </button>
              </form>

              {user?.role === Role.SCHOOL_ADMIN && (
                <Tooltip id="status-change" type="light">
                  Click to change status.
                </Tooltip>
              )}
            </>
          );
        },
      },
    ],
    [API, user?.role, user.schools],
  );

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const { isLoading, data, isSuccess } = useQuery(
    ['fees', state.queryPageIndex, state.queryPageSize, searchString],
    () => API.fetchFees(
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      user.schools[0].id,
    ),
  );

  return (
    <>
      <UpdateService
        isUpdateService={isEdit}
        onClose={() => {
          setIsEdit(false);
          setServiceInfo({});
        }}
        info={serviceInfo}
      />
      <Layout menuItems={menu}>
        <Content title="Services">
          <CustomContext.Provider value={stateProvider}>
            <Table
              searchLabel="search services"
              columns={columns}
              data={isSuccess ? data?.data : []}
              meta={isSuccess ? data?.meta : []}
              loading={isLoading}
              countPage={
                isSuccess
                  ? Math.ceil(state.totalCount / state.queryPageSize)
                  : undefined
              }
              onChangeCallback={(e: any) => handleSearch(e)}
              search
            />
          </CustomContext.Provider>
        </Content>
      </Layout>
    </>
  );
};

export default Services;
