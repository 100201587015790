export const phoneRegexWithCode = new RegExp(/2507[2389]\d{7}/g);
export const phoneRegexWithoutCode = new RegExp(/07[2389]\d{7}/g);
export const idCardRegex = new RegExp(/^119|120\d{13}$/g);
export const phoneRegex = new RegExp('^2507[2389]');
export const regPhone = /^(25078|25073|25079|25072)\d{7}$/;
// eslint-disable-next-line max-len
export const AccFormat = (accNumber: string): string => accNumber.replace(/(\d{5})(\d{8})(\d{2})/, '$1-$2-$3');
export const bkAcc = /(\d{5})-(\d{8})-(\d{2})/;

export const phoneRegex250 = new RegExp('^2507[2389]');
export const urlReg = new RegExp(
  '^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$',
  'i',
); // fragment locator
