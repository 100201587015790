/* eslint-disable camelcase */
import React from 'react';
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { InputText, Button } from '../components';
import { ROUTE } from '../routes';
import AuthLayout from '../components/Layout/AuthLayout';

interface IResetPassword {
  user_id: string;
  verification_code: string;
  password: string;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

const resetPassword = async (payload: IResetPassword): Promise<any> => {
  const response = await axios({
    method: 'POST',
    url: `${BASE_URL}/auth/set-password`,
    responseType: 'json',
    data: payload,
  });
  return response.data;
};

const SetNewPassword: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userId = query.get('user_id') as string;

  const { mutate } = useMutation(resetPassword, {
    onSuccess(res) {
      const toastID = toast.success(res?.message, {
        onClose: () => {
          window.location.href = NODE_ENV === 'staging' ? `/mis${ROUTE.HOME}` : `${ROUTE.HOME}`;
        },
      });

      setTimeout(() => {
        toast.dismiss(toastID);
      }, 5000);
    },
  });

  const {
    handleSubmit, touched, errors, handleChange, handleBlur, values,
  } = useFormik({
    initialValues: {
      verification_code: '',
      password: '',
      confirmPassword: '', // Change to match validation schema
    },
    validationSchema: Yup.object({
      verification_code: Yup.string().required('The verification code is required.'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters.')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
        .matches(/[0-9]/, 'Password must contain at least one number.')
        .matches(/[!@#$%^&*]/, 'Password must contain at least one special character.')
        .required('Password is required.'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match.')
        .required('Confirm password is required.'),
    }),
    onSubmit: (submittedData) => {
      mutate({
        user_id: userId,
        verification_code: submittedData.verification_code,
        password: submittedData.password,
      });
    },
  });

  return (
    <AuthLayout
      navLinks={(
        <Link
          to={ROUTE.HOME}
          className="text-blue bg-white hover:bg-blue-50 font-medium rounded
          text-lg px-5 py-2.5 me-2 mb-2 focus:outline-none"
        >
          Login
        </Link>
      )}
    >
      <p className="text-center text-3xl text-blue my-6">Set New Password.</p>

      <form className="flex flex-col pt-3" onSubmit={handleSubmit}>
        <InputText
          type="text"
          label="Verification Code"
          id="verification_code"
          placeholder="000000"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.verification_code}
          errorMsg={touched.verification_code
            && errors.verification_code ? errors.verification_code : ''}
        />
        <InputText
          type="password"
          label="New Password"
          id="password"
          placeholder="*********"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          errorMsg={touched.password && errors.password ? errors.password : ''}
        />
        <InputText
          type="password"
          label="Confirm Password"
          id="confirmPassword" // Change to match validation schema
          placeholder="*********"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword} // Change to match validation schema
          errorMsg={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ''}
        />
        <Button type="submit" variant="primary" className="mt-6">
          Set
        </Button>
      </form>
    </AuthLayout>
  );
};

export default SetNewPassword;
