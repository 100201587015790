import React from 'react';

type Props = {
  title: string;
  description: string;
  children: React.ReactNode;
  optional?: boolean
};

const FormLayout: React.FC<Props> = ({
  title,
  description,
  children,
  optional = false,
}: Props) => (
  <div className="md:grid md:grid-cols-3 md:gap-6 mb-3">
    <div className="md:col-span-1">
      <div className="px-4 sm:px-0">
        <h3 className="text-lg font-medium leading-6 text-black">{title}</h3>
        {optional && <span className="text-blue italic">Optional</span>}
        <p className="mt-1 text-sm text-gray-dark">{description}</p>
      </div>
    </div>
    <div className="mt-5 md:mt-0 md:col-span-2 px-4 py-5 pr-0 rounded-md">
      {children}
    </div>
  </div>
);

export default FormLayout;
