/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { BehaviorSubject } from 'rxjs';
import { msg } from './http-messages';
import { history } from '.';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
const currentUserSubject = new BehaviorSubject(currentUser);
class APIFD {
  aFDService: AxiosInstance;

  constructor() {
    const FDService = axios.create();

    FDService.interceptors.request.use(
      (config: AxiosRequestConfig | any) => {
        const { token } = JSON.parse(
          sessionStorage.getItem('currentUser') || '{}',
        );
        config.headers['Content-Type'] = 'multipart/form-data';
        // config.headers['Access-Control-Allow-Origin'] = '*';
        // config.headers['Accept'] = 'multipart/form-data';
        if (token) config.headers['Authorization'] = `JWT ${token}`;

        return config;
      },
      (error) => Promise.reject(error),
    );
    FDService.interceptors.response.use(
      this.handleSuccessResponse,
      this.handleErrorResponse,
    );
    this.aFDService = FDService;
  }

  // eslint-disable-next-line class-methods-use-this
  handleSuccessResponse(
    response: Record<string, any>,
  ): Promise<any> | Record<string, any> {
    switch (response.data.status) {
      case 201:
        toast.success(response.data.message);
        break;
      case 200:
        return response;
      default:
        toast.success(`${response.data.status}: ${response.data.message}`);
        break;
    }
    return Promise.resolve(response);
  }

  handleErrorResponse = (error: Record<string, any>): Promise<void> => {
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          toast.error(
            `${error.response.data.message || msg.ACTION_UNAUTHORIZED}`,
          );
          history.push('/');
          break;
        case 403:
          toast.error(
            `${error.response.data.message || msg.ACTION_UNAUTHORIZED}`,
          );
          history.push('/');
          break;
        case 404:
          toast.error(
            ` ${error.response.data.message || msg.ACTION_NOT_FOUND}`,
          );
          break;
        case 408:
          toast.error(`${msg.ACTION_REQUEST_TIMED_OUT}`);
          break;
        case 409:
          toast.warn(`${error.response.data.message}`);
          break;
        case 500:
          toast.error(
            `${error.response.data.message || msg.ACTION_INTERNAL_SERVER}`,
          );
          break;
        case 502:
          toast.warn(`${msg.ACTION_BAD_GATEWAY}`);
          break;
        case 503:
          toast.warn(`${msg.ACTION_SERVICE_UNAVAILABLE}`);
          break;
        case 507:
          toast.warn(`${msg.ACTION_INSUFFICIENT_STORAGE}`);
          break;
        case 400:
          toast.error(`${error.response.data.message}`);
          break;
        default:
          toast.error(`${error.response.statusText}`);
          break;
      }
    }
    return Promise.reject(error);
  };

  post(
    path: string,
    payload: FormData,
    callback: (args: Record<string, any>) => void,
  ): Promise<any> {
    return this.aFDService({
      method: 'POST',
      url: `${BASE_URL}${path}`,
      data: payload,
    }).then((response: Record<string, any>) => callback(response.data));
  }

  // eslint-disable-next-line class-methods-use-this
  logout(): void {
    // remove user from local storage to log user out
    sessionStorage.clear();
    currentUserSubject.next(null);
  }
}

export { APIFD };
