import React from 'react';
import Select from 'react-select';

import { customStyles } from './customStyles';

type CustomProps = {
  id?: string;
  label?: string;
  errorMsg?: string;
  optional?: boolean;
  className?: string;
    [x:string]: any;
}

export interface ISelectedValue {
  label: string | number;
  value: string | number;
}

const SelectComponent: React.FC<CustomProps> = ({
  className,
  id,
  label,
  errorMsg,
  optional = false,
  ...rest
}: CustomProps) => (
  <div className={`flex flex-col ${className}`}>
    <div className="w-full flex justify-between items-baseline">
      <label
        htmlFor={id}
        className="text-xs uppercase
    mb-1 ml-4 mt-2 text-blue"
      >
        {label}
      </label>
      {optional && <span className="text-blue italic text-xs">optional</span>}
    </div>
    <Select
      {...rest}
      id={id}
      styles={customStyles}
    />

    <p className=" text-xs font-light text-red mt-1 ml-4">{errorMsg}</p>
  </div>
);

export default SelectComponent;
