import React from 'react';

type Props = {
  stats:
  {
    id: number;
    description: string;
    numbers: string;
    icon: React.ReactNode;
    iconBgColor: string;
  }[]
};

const Stats: React.FC<Props> = ({ stats }: Props) => (
  <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
    {stats.map(({
      id, description, numbers, icon, iconBgColor,
    }) => (
      description ? (
        <div
          key={id}
          className="flex items-center p-4 bg-white
      rounded-lg border-gray-bg border shadow-xs dark:bg-gray-800"
        >
          <div className={`p-3 mr-4 text-orange-500 ${iconBgColor} 
        rounded-full dark:text-orange-100 dark:bg-orange-500`}
          >
            {icon}
          </div>
          <div>
            <p className="mb-2 text-sm font-medium
          text-gray-600 dark:text-gray-400"
            >
              {description}
            </p>
            <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {numbers}
            </p>
          </div>
        </div>
      ) : null
    ))}

  </div>
);

export default Stats;
