/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FaTimes } from 'react-icons/fa';

type Props = {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  footerContent?: React.ReactNode;
};

const Modal: React.FC<Props> = ({
  title,
  children,
  onClose,
  isOpen,
  footerContent,
}: Props) => {
  if (!isOpen) return null;

  const close = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if (onClose) onClose();
  };

  return (
    <>
      <div
        className="overflow-x-hidden overflow-y-auto
      fixed inset-0 z-50 outline-none focus:outline-none
      justify-center items-center transition-all duration-500
      w-screen h-screen"
        id="modal"
      >
        <div
          className="relative w-auto mt-40 mx-auto max-w-3xl transition-all duration-500"
        >
          {/* <!--content--> */}
          <div className="border-0 rounded-lg shadow-lg
        relative flex flex-col w-full bg-white
        outline-none focus:outline-none"
          >
            {/* <!--header--> */}
            <div className="flex items-start justify-between p-5
           border-b border-solid border-gray-bg rounded-t"
            >
              <span className="text-xl font-medium text-black">{title}</span>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0
               text-gray float-right text-xl leading-none
                font-medium outline-none focus:outline-none hover:text-2xl"
                onClick={(e) => close(e)}
              >
                <FaTimes />
              </button>
            </div>
            {/* <!--body--> */}
            <div className="relative p-6 flex-auto">
              <span className="my-4 text-black text-lg leading-relaxed">{children}</span>
            </div>
            {/* <!--footer--> */}
            { React.isValidElement(footerContent) && (
            <div
              className="flex items-center justify-end p-6 border-t
            border-solid border-gray-bg rounded-b"
            >
              {footerContent}
            </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        id="modal-example-regular-backdrop"
      />
    </>
  );
};

export default Modal;
