import React from 'react';
import Button from './Button';

interface ConfirmModalProps {
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    isLoading: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  message, onConfirm, onCancel, isLoading,
}) => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
    <div className="bg-white p-6 rounded shadow-lg">
      <p className="mb-4">{message}</p>
      <div className="flex justify-end gap-4">
        <Button
          type="button"
          variant="secondary"
          onClick={onCancel}
          disabled={isLoading}
        >
          No
        </Button>

        <Button
          type="button"
          onClick={onConfirm}
          disabled={isLoading}
        >
          {isLoading ? 'Changing...' : 'Yes'}
        </Button>
      </div>
    </div>
  </div>
);

export default ConfirmModal;
