/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Row } from 'react-table';

type Props = {
    row: Row,
    rowProps: Record<string, any>,
    visibleColumns: string,
    data: Record<string, any>[],
    loading?: boolean
}

const SubRows: React.FC<Props> = ({
  row, rowProps, visibleColumns, data, loading,
}) => {
  if (loading) {
    return (
      <tr>
        <td colSpan={visibleColumns.length - 1}>
          Loading...
        </td>
      </tr>
    );
  }

  // error handling here :)

  return (
    <>
      {data.map((x, i) => (
        <tr
          {...rowProps}
          // eslint-disable-next-line react/no-array-index-key
          key={`${rowProps.key}-expanded-${i}`}
          className="text-black border-gray-bg border-b bg-gray-light hover:bg-blue-alert"
        >
          {row.cells.map((cell: any) => (
            <td
              {...cell.getCellProps()}
              className="px-4 py-5"
            >
              {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell', {
                value:
                      cell.column.accessor
                      && cell.column.accessor(x, i),
                row: { ...row, original: x },
              })}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default SubRows;
