/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useQuery, useMutation } from 'react-query';
import Moment from 'moment';
import Tooltip from 'react-tooltip';
import { FaUserEdit } from 'react-icons/fa';
import { BiDownload, BiFilterAlt } from 'react-icons/bi';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md';
import {
  Table, Button, Filter,
} from '../../../../components';
import { IFilters } from '../../../../components/Table/Filter';
import School, { IStatus } from '../../local-helpers/requests';
import {
  convertJsonToXl,
  getOnlyStudents,
  StudentsHeaders,
} from '../../../../helpers/functions-and-objects';
import { tableReducer } from '../../../../components/Table/TableReducer';
import CustomContext from '../../../../contexts/CustomContext';
import UpdateStudent from './UpdateStudent';
import { Role } from '../../../../helpers';
import CreateStudent from './CreateStudent';
import { ISelectedValue } from '../../../../components/Select';
import { useCurrentUser } from '../../../../contexts/UserContext';
import ConfirmModal from '../../../../components/ConfirmModal';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const statuses = [{ label: 'active', value: 'active' }, { label: 'inactive', value: 'inactive' }];

const Members: React.FC = () => {
  const { user } = useCurrentUser();
  const schoolName = user?.schools?.[0]?.name;
  const schoolId = user?.schools[0]?.id;
  const isGasabo = schoolName?.includes('Gasabo');
  const API = React.useMemo(() => new School(), []);
  const [campuses, setCampuses] = React.useState<ISelectedValue[]>([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isStudentModal, setStudentModal] = React.useState(false);
  const [studentInfo, setStudentInfo] = React.useState<Record<string, any>>({});
  const [departments, setDepartments] = React.useState<Array<IFilters>>([]);
  const [searchString, setSearchString] = React.useState('');
  const [checkedDepartments, setCheckedDepartments] = React.useState<Array<string>>([]);
  const [selectedStatus, setSelectedStatus] = React.useState('');

  useQuery('campuses', () => API.fetchCampuses(schoolId), {
    onSuccess(data) {
      setCampuses([]);
      const { data: campusData } = data;
      const campusSelection = campusData.map((cd: Record<string, any>) => ({
        label: cd?.name,
        value: cd?.id,
      }));

      setCampuses((prev) => ([...prev, ...campusSelection]));
    },
  });

  useQuery('departments', () => API.fetchDepartments(100, schoolId), {
    retry: false,
    onSuccess(data) {
      setDepartments([]);
      const { data: departmentData } = data;
      const departmentsSelection = departmentData.map((d: Record<string, any>) => ({
        label: d?.name,
        value: d?.id,
      }));
      setDepartments((prev) => ([...prev, ...departmentsSelection]));
    },
  });

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const {
    isLoading, data, isSuccess, refetch,
  } = useQuery(
    ['members',
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      checkedDepartments,
      selectedStatus],
    () => API.fetchMembers(
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      checkedDepartments,
      selectedStatus,
      user.schools[0].id,
    ),
  );

  const columns = React.useMemo(
    () => [
      {
        Header: isGasabo ? 'Stand No.' : 'Registration No.',
        accessor: (row: Record<string, any>) => row?.user?.registration_number,
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => original?.user?.registration_number,
      }, {
        Header: isGasabo ? 'RWF To be Paid' : 'Sage ID',
        accessor: 'sage_id',
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => (isGasabo ? original?.sage_id?.toLocaleString()
          : original?.sage_id),
      },
      {
        Header: 'First Name',
        accessor: (row: Record<string, any>) => row?.user?.first_name,
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => original?.user?.first_name,
      },
      {
        Header: 'Last Name',
        accessor: (row: Record<string, any>) => row?.user?.last_name,
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => original?.user?.last_name,
      },
      {
        Header: isGasabo ? 'Zone' : 'Department',
        accessor: (row: Record<string, any>) => row?.department?.name,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.department?.name,
      },
      {
        Header: isGasabo ? 'Location' : 'Campus',
        accessor: (row: Record<string, any>) => row?.campus?.name,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.campus?.name,
      },
      {
        Header: 'Created at',
        accessor: (row: Record<string, any>) => row?.user?.created_at,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.user?.created_at)
          .format('DD/MM/YYYY, h:mm:ss a') || 'created at',
      },
      {
        Header: 'Updated at',
        accessor: (row: Record<string, any>) => row?.user?.updated_at,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.user?.updated_at)
          .format('DD/MM/YYYY, h:mm:ss a')
          || 'updated at',
      },
      user?.role === Role.SCHOOL_ADMIN ? {
        Header: 'Action',
        accessor: 'action',
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => {
          const {
            isLoading: isDeleting, mutate,
          } = useMutation((payload: any) => API.deleteUser(
            payload, user.schools[0].id,
          ), {
            onSuccess() {
              refetch();
            },
          });

          const [showConfirm, setShowConfirm] = React.useState(false);

          const handleDelete = (): void => {
            mutate({
              user_id: original?.user_id,
            });
          };

          const handleSubmit = (e: React.FormEvent): void => {
            e.preventDefault();
            setShowConfirm(true);
          };

          return (
            <span className="flex gap-1">
              <button
                type="button"
                className="text-yellow background-transparent font-bold uppercase rounded
                outline-none focus:outline-none ease-linear transition-all
                duration-150 hover:bg-yellow-100 active:bg-green-alert text-xs px-4 py-2"
                onClick={() => { setIsEdit(true); setStudentInfo(original); }}
              >
                <FaUserEdit size={18} />
              </button>
              <button
                type="button"
                className=" text-red background-transparent font-bold uppercase rounded
                outline-none focus:outline-none ease-linear transition-all
                duration-150 hover:bg-red-100 active:bg-green-alert text-xs px-4 py-2"
                onClick={handleSubmit}
              >
                <MdDeleteForever size={18} />
              </button>
              {showConfirm && (
                <ConfirmModal
                  message={`Are you sure you want to delete:  
                     ${original?.registration_number
                    } - ${original?.user?.first_name} ${original?.user?.last_name}?`}
                  onConfirm={() => {
                    handleDelete();
                    setShowConfirm(false);
                  }}
                  onCancel={() => setShowConfirm(false)}
                  isLoading={isDeleting}
                />
              )}
            </span>
          );
        }
        ,
      } : { id: 'no-edit-allowed', Cell: () => '' },
      {
        Header: 'Status',
        accessor: (row: any) => row?.status,
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          const {
            mutate, isLoading: isChangingStatus,
          } = useMutation((payload: IStatus) => API.changeStatus(
            payload, user.schools[0].id,
          ), {
            onSuccess() {
              refetch();
            },
          });

          const [showConfirm, setShowConfirm] = React.useState(false);

          const handleStatusChange = (): void => {
            mutate({
              action: original?.status === 'active' ? 'deactivate' : 'activate',
              user_id: original?.user_id,
            });
          };

          const handleSubmit = (e: React.FormEvent): void => {
            e.preventDefault();
            setShowConfirm(true);
          };

          const buttonStyles = original?.status === 'active'
            ? 'text-green-alertText bg-green-alertText'
            : 'text-red-alertText bg-red-alertText';

          if (user?.role === Role.ACCOUNTANT) {
            return (
              <div
                data-tip
                data-for="status-change"
                className={`text-xs px-2 text-center font-medium bg-opacity-10
                     rounded py-0.5 ${buttonStyles}`}
              >
                {original?.status}
              </div>
            );
          }
          return (
            <>
              <form onSubmit={handleSubmit}>
                <button
                  type="submit"
                  data-tip
                  data-for="status-change"
                  className={`text-xs px-2 font-medium bg-opacity-10
                     rounded py-0.5 mr-1 ${buttonStyles}`}
                  disabled={isChangingStatus}
                >
                  {isChangingStatus ? 'Changing...' : original?.status}
                </button>
              </form>
              {showConfirm && (
                <ConfirmModal
                  message={`Are you sure you want to change the status to
                     ${original?.status === 'active' ? 'inactive' : 'active'
                    }?`}
                  onConfirm={() => {
                    handleStatusChange();
                    setShowConfirm(false);
                  }}
                  onCancel={() => setShowConfirm(false)}
                  isLoading={isChangingStatus}
                />
              )}

              {user?.role === Role.SCHOOL_ADMIN && (

                <Tooltip id="status-change" type="light">
                  Click to change status.
                </Tooltip>
              )}
            </>
          );
        },
      },
    ],
    [API, user?.role, user.schools, isGasabo, refetch],
  );

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const {
    data: dData, isSuccess: dSuccess,
  } = useQuery(
    ['members download',
      searchString,
      checkedDepartments,
      selectedStatus,
    ],
    () => API.downloadMembers(
      searchString,
      checkedDepartments,
      selectedStatus,
      user.schools[0].id,
    ),
  );

  const exportStudents = (): void => {
    if (dSuccess) {
      const docName = `Students list ${Moment().format('DD-MM-YYYY HH:MM')}`;
      const dataToDownload = dData?.data?.map((s: Record<string, any>) => [
        s.user.registration_number,
        s.sage_id || '',
        s?.user?.first_name,
        s?.user?.last_name,
        s?.user?.gender || '',
        s?.department?.name,
        s?.campus?.name]);
      convertJsonToXl(dataToDownload, StudentsHeaders, docName);
    }
  };

  return (
    <>
      <CreateStudent
        isStudentCreated={isStudentModal}
        onClose={() => setStudentModal(false)}
        students={[]}
        campuses={campuses}
        departments={departments}
        isGasabo
      />
      <UpdateStudent isUpdateStudent={isEdit} onClose={() => setIsEdit(false)} info={studentInfo} />
      <CustomContext.Provider value={stateProvider}>
        <div className="w-full flex justify-end gap-2">
          <Filter
            title={(
              <span className="flex">
                <BiFilterAlt size={20} className="mr-1" />
                {' '}
                <span>Filter by status</span>
              </span>
            )}
            filters={statuses}
            onSelect={(args) => setSelectedStatus(args.label)}
            radio
          />
          <Filter
            title={(
              <span className="flex">
                <BiFilterAlt size={20} className="mr-1" />
                {' '}
                <span>{isGasabo ? 'filter by zone' : 'filter by department'}</span>
              </span>
            )}
            filters={departments}
            onCheck={(args) => setCheckedDepartments(args.map((a) => a.value))}
          />
          <Button type="button" className="flex" onClick={() => exportStudents()}>
            <BiDownload size={20} className="mr-1" />
            Download list
          </Button>
          &nbsp;&nbsp;
          {user?.role === Role.SCHOOL_ADMIN && (
          <Button type="button" className="flex" onClick={(): void => { setStudentModal(true); }}>
            <AiOutlineUserAdd size={20} className="mr-1" />
            {isGasabo ? 'Register seller' : 'Register student'}
          </Button>
          )}
        </div>

        <Table
          searchLabel={isGasabo ? 'search sellers' : 'search students'}
          data={isSuccess ? getOnlyStudents(data?.data) : []}
          columns={columns}
          meta={isSuccess ? data?.meta : []}
          countPage={
            isSuccess
              ? Math.ceil(state.totalCount / state.queryPageSize)
              : undefined
          }
          loading={isLoading}
          onChangeCallback={(e: any) => handleSearch(e)}
          search
        />
      </CustomContext.Provider>
    </>
  );
};
export default Members;
