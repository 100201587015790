/* eslint-disable camelcase */
import React from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { InputText, FormLayout, Button } from '../../../components';
import { API, urlReg } from '../../../helpers';

const api = new API();
interface ISchool {
  name: string,
  address: string,
  email: string,
  acronym: string,
  website: string
}

const SystemAdmin: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      sc_name: '',
      sc_abbreviation: '',
      sc_email: '',
      sc_website: '',
      sc_address: '',
    },
    validationSchema: Yup.object({
      sc_name: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('University name is required.'),
      sc_abbreviation: Yup.string().min(2, 'Must be at least 2 characters'),
      sc_email: Yup.string().email('Invalid email address.'),
      sc_website: Yup.string().matches(urlReg, 'Website is invalid.'),
      sc_address: Yup.string()
        .min(10, 'Must be at least 10 characters.')
        .required('Address is required.'),
    }),
    onSubmit: (values) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      mutate({
        name: values.sc_name,
        address: values.sc_address,
        email: values.sc_email,
        acronym: values.sc_abbreviation,
        website: values.sc_website,
      });
    },
  });

  const {
    mutate, isLoading,
  } = useMutation(
    (payload: ISchool) => api.post('/schools', payload, (res) => toast.success(res.message)),
    {
      onSuccess() {
        formik.resetForm();
      },
    },
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout
        title="University Information"
        description="Brief information about
          the university."
      >
        <div className="grid grid-cols-2 gap-5">
          <InputText
            id="sc_name"
            type="text"
            placeholder="University of John Doe"
            value={formik.values.sc_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="name"
            errorMsg={
              formik.touched.sc_name && formik.errors.sc_name
                ? formik.errors.sc_name
                : ''
            }
          />
          <InputText
            id="sc_abbreviation"
            type="text"
            placeholder="UJD"
            value={formik.values.sc_abbreviation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="acronym"
            errorMsg={
              formik.touched.sc_abbreviation && formik.errors.sc_abbreviation
                ? formik.errors.sc_abbreviation
                : ''
            }
          />
          <InputText
            id="sc_email"
            type="email"
            placeholder="ujd@support.com"
            value={formik.values.sc_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="email address"
            errorMsg={
              formik.touched.sc_email && formik.errors.sc_email
                ? formik.errors.sc_email
                : ''
            }
          />
          <InputText
            id="sc_website"
            type="url"
            placeholder="https://ujd.rw"
            value={formik.values.sc_website}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="website"
            errorMsg={
              formik.touched.sc_website && formik.errors.sc_website
                ? formik.errors.sc_website
                : ''
            }
          />
          <InputText
            id="sc_address"
            type="text"
            placeholder="District, KG 000 st"
            value={formik.values.sc_address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="address"
            errorMsg={
              formik.touched.sc_address && formik.errors.sc_address
                ? formik.errors.sc_address
                : ''
            }
          />
        </div>
        <div className="flex justify-end mt-6">
          <Button
            type="reset"
            variant="secondary"
            onClick={() => formik.resetForm()}
          >
            Cancel
          </Button>
          <Button type="submit" className="ml-4" loading={isLoading}>
            Create university
          </Button>
        </div>
      </FormLayout>
    </form>
  );
};

export { SystemAdmin };
