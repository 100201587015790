import React from 'react';
import Moment from 'moment';
import { useQuery } from 'react-query';
import { BiDownload } from 'react-icons/bi';
import { toast } from 'react-toastify';
import {
  Layout, Content, Table, DateRange, Badge, Button,
} from '../../components';
import { useSchoolAdminMenu } from './local-helpers/MenuItems';
import { useAccountantMenu } from '../Accountant/MenuItems';
import School from './local-helpers/requests';
import { Role } from '../../helpers';
import { tableReducer } from '../../components/Table/TableReducer';
import CustomContext from '../../contexts/CustomContext';
import TransactionDetails from '../Admin/TransactionDetails';
import { convertJsonToXl, TransactionsHeaders } from '../../helpers/functions-and-objects';
import { useCurrentUser } from '../../contexts/UserContext';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const Transactions: React.FC = () => {
  const { user } = useCurrentUser();
  const schoolName = user?.schools?.[0]?.name;
  const isGasabo = schoolName?.includes('Gasabo');
  const saMenu = useSchoolAdminMenu();
  const accMenu = useAccountantMenu();

  const API = new School();

  const [searchString, setSearchString] = React.useState('');
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');
  const [isViewMore, setIsViewMore] = React.useState(false);
  const [transactionInfo, setTransactionInfo] = React.useState<Record<string, any>>({});

  React.useEffect(() => () => toast.dismiss(), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'External ID',
        accessor: 'external_transaction_id',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Payed by',
        accessor: (row: Record<string, any>) => `${row?.user?.first_name} ${row?.user?.last_name}`,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => `${original?.user?.first_name}
        ${original?.user?.last_name}`,
      },
      {
        Header: 'Phone number',
        accessor: (row: Record<string, any>) => row?.user?.phone_number,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => `+${original?.user?.phone_number}`,
      },
      {
        Header: isGasabo ? 'Zone' : 'Department',
        accessor: (row: Record<string, any>) => row?.department?.name,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.department?.name,
      },
      {
        Header: 'Payed on',
        accessor: 'created_at',
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.created_at)
          .format('DD/MM/YYYY, h:mm:ss a'),
      },
      {
        Header: isGasabo ? 'Month' : 'Term',
        accessor: 'term',
      },
      {
        Header: isGasabo ? '' : 'Academic year',
        accessor: 'academic_year',
      },
      {
        Header: 'Channel',
        accessor: 'payment_channel_name',
      },
      {
        Header: "Recipient's bank account ",
        accessor: (row: Record<string, any>) => row?.bank_account?.bank_account_number,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.bank_account?.bank_account_number
          || '',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          <Badge
            variant={original?.status === 'success' ? 'green' : 'red'}
          >
            {original?.status}
          </Badge>
        ),
      },
      {
        Header: '',
        accessor: 'view more',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          <button
            type="button"
            onClick={() => { setIsViewMore(true); setTransactionInfo(original); }}
            className="text-blue-alertText text-xs hover:underline "
          >
            View more
          </button>
        ),
      },
    ],
    [isGasabo],
  );

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const { data, isLoading, isSuccess } = useQuery(
    ['transactions', state.queryPageIndex, state.queryPageSize, searchString, start, end],
    () => API.fetchTransactions(
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      start,
      end,
      user.schools[0].id,
    ),
  );

  const {
    data: dtData,
    isSuccess: dtIsSuccess,
  } = useQuery('download transactions', () => API.downloadTransactions());

  const exportTransactions = (): void => {
    if (dtIsSuccess) {
      let dataforSchool = dtData
        ?.data
        ?.filter((t: any) => t?.school_id === user.schools[0].id);
      if (start.trim().length > 0 && end.trim().length > 0) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        // Set the end date to the end of the day on 2024-07-18
        endDate.setHours(23, 59, 59, 999);
        dataforSchool = dataforSchool.filter((item: any) => {
          const createdAtDate = new Date(item.created_at);
          return createdAtDate >= startDate && createdAtDate <= endDate;
        });
      }
      const dataToDownload = dataforSchool?.map((t: Record<string, any>) => [
        t?.academic_year,
        t?.sage_id,
        t?.amount,
        t?.account_number,
        t?.channel,
        t?.currency,
        t?.department?.name,
        t?.description,
        t?.external_transaction_id,
        t?.initial_slip_number,
        t?.created_at,
        t?.payer_names,
        t?.user?.phone_number,
        t?.school_name,
        t?.service_name,
        t?.slip_number,
        t?.term,
        t?.created_at,
        t?.updated_at,
        t?.type,
      ]);
      convertJsonToXl(dataToDownload, TransactionsHeaders, 'transactions');
    }
  };

  return (
    <Layout
      menuItems={
        user?.role === Role.SCHOOL_ADMIN
          ? saMenu
          : user?.role === Role.ACCOUNTANT
            ? accMenu
            : []
      }
    >
      <TransactionDetails
        isGasabo={isGasabo}
        isViewMore={isViewMore}
        details={transactionInfo}
        onClose={() => { setIsViewMore(false); setTransactionInfo({}); }}
      />
      <Content title="Transactions">
        <CustomContext.Provider value={stateProvider}>
          <div className="w-full flex justify-between">
            <Button
              type="button"
              onClick={() => exportTransactions()}
              className="flex"
            >
              <BiDownload size={20} className="mr-1" />
              EXPORT
            </Button>
            <DateRange timeFrame={(from, to) => { setStart(from); setEnd(to); }} />
          </div>
          <Table
            searchLabel="search transactions"
            columns={columns}
            data={isSuccess ? data?.data : []}
            meta={isSuccess ? data?.meta : []}
            countPage={
            isSuccess
              ? Math.ceil(state.totalCount / state.queryPageSize)
              : undefined
          }
            loading={isLoading}
            onChangeCallback={(e: any) => handleSearch(e)}
            search
          />
        </CustomContext.Provider>
      </Content>
    </Layout>
  );
};
export default Transactions;
