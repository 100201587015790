/* eslint-disable camelcase */
import React from 'react';
import { useQuery } from 'react-query';
import Moment from 'moment';
// import Tooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { System } from './local-helpers/requests';
import { menu } from './local-helpers/MenuItems';
import {
  Layout,
  Content,
  Table,
} from '../../components';
import CustomContext from '../../contexts/CustomContext';
import { tableReducer } from '../../components/Table/TableReducer';

const system = new System();

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const Users: React.FC = () => {
  const [searchString, setSearchString] = React.useState('');

  React.useEffect(() => () => toast.dismiss(), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'username',
        accessor: 'username',
      },
      {
        Header: 'First name',
        accessor: 'first_name',
      },
      {
        Header: 'Last name',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => original
          ?.role
          ?.split('_')
          ?.join(' '),
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          Moment(original?.created_at)
            .format('DD/MM/YYYY, h:mm:ss a')),
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          Moment(original?.updated_at)
            .format('DD/MM/YYYY, h:mm:ss a')),
      },
      // {
      //   Header: 'User Status',
      //   accessor: (row: any) => [row?.status],
      //   Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
      //     const {
      //       mutate, isLoading,
      //     } = useMutation((payload: IToggleActivation) => System.toggleUserActivation(payload));
      //     return (
      //       <>
      //         <form
      //           onSubmit={(e) => {
      //             e.preventDefault();
      //             mutate({
      //               action: original?.status === 'active'
      //                 ? 'deactivate'
      //                 : 'activate',
      //               user_id: original?.user_id,
      //             });
      //           }}
      //         >
      //           <button
      //             type="submit"
      //             data-tip
      //             data-for="status-change"
      //             className={`text-xs px-2 font-medium
      //           ${original?.status === 'active'
      //               ? 'text-green-alertText bg-green-alertText'
      //               : 'text-red-alertText bg-red-alertText'
      //               }
      //             bg-opacity-10 rounded py-0.5 mr-1`}
      //           >
      //             {isLoading ? 'changing...' : original?.status}
      //           </button>
      //         </form>

      //         <Tooltip id="status-change" type="light">
      //           {' '}
      //           Click to change status.
      //         </Tooltip>
      //       </>
      //     );
      //   }
      //   ,
      // },
    ],
    [],
  );

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const { isLoading, data, isSuccess } = useQuery(
    ['users', state.queryPageIndex, state.queryPageSize, searchString],
    () => system.getUsers(state.queryPageIndex,
      state.queryPageSize,
      searchString),
  );

  return (
    <>
      <Layout menuItems={menu}>
        <Content title="Users">
          <CustomContext.Provider value={stateProvider}>
            <Table
              searchLabel="search users"
              data={isSuccess ? data?.data : []}
              columns={columns}
              meta={isSuccess ? data?.meta : []}
              countPage={
              isSuccess
                ? Math.ceil(state.totalCount / state.queryPageSize)
                : undefined
            }
              loading={isLoading}
              onChangeCallback={(e: any) => handleSearch(e)}
              search
            />
          </CustomContext.Provider>
        </Content>
      </Layout>
    </>
  );
};

export default Users;
