/* eslint-disable react/react-in-jsx-scope */
import { IoSchoolOutline } from 'react-icons/io5';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { ROUTE } from '../../routes';
import { useCurrentUser } from '../../contexts/UserContext';

export const useAccountantMenu = (): any => {
  const { user } = useCurrentUser();
  const schoolName = user?.schools?.[0]?.name;
  const isGasabo = schoolName?.includes('Gasabo');

  // Dynamically generate menu based on user data
  const menu = [
    {
      id: 2,
      icon: <IoSchoolOutline color="#ffffff" />,
      menuName: isGasabo ? 'Sellers' : 'Students',
      path: ROUTE.SCHOOL_ADMIN_STUDENTS,
    },
    {
      id: 3,
      icon: <CgArrowsExchangeAlt color="#ffffff" />,
      menuName: 'Payments report',
      path: ROUTE.SCHOOL_PAYMENTS_REPORT,
    },
  ];

  return menu;
};
