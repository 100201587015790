/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './styles.css';

type Props = {
  label?: string;
  id: string;
  errorMsg?: string;
  className?: string,
  optional?: boolean,
  [x: string]: any
};

const TextArea: React.FC<Props> = ({
  label,
  id,
  errorMsg,
  className,
  optional = false,
  ...rest
}: Props) => (
  <div className={`flex flex-col ${className}`}>
    <div className="w-full flex justify-between items-baseline">
      <label
        htmlFor={id}
        className="text-xs uppercase
    mb-1 ml-4 mt-2 text-blue"
      >
        {label}
      </label>
      {optional && <span className="text-blue italic text-xs">optional</span>}

    </div>
    <textarea {...rest} id={id} className="input-text" />
    <p className=" text-xs font-light text-red mt-1 ml-4">{errorMsg}</p>
  </div>
);

export default TextArea;
